import React, { Component } from 'react';
import { DrcTooltip, DrcMain, DrcPageDataMaintenance } from 'driscolls-react-components';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { setPageTitleAction, showToast, setErrorsAction } from '../actions/actions';
import { connect } from 'react-redux';
import MasterDataUtilities from '../data/MasterDataUtilities';
import GridStyles from '../styles/gridStyles';
import Translate from './Translate';
import { Middleware } from 'one-ring';
import APIEndPoints from '../services/api';
import { middlewareNoMappingConfig, middlewareConfig, toastType } from '../data/constants';

const pageTitle = 'Master Data Management';

const styles = (theme) => ({
    main: {
        maxWidth: '96vw !important',
        borderRadius: '4px'
    },
    panel: {
        maxWidth: 'calc(100vw - 40px) !important',
        border: 'none',
        padding: 0,
        margin: 0
    },
    gridStyles: {
        ...GridStyles.styles(theme),
        '& th.p-selection-column': {
            width: '40px !important',
            display: 'flex'
        },
        '& .p-checkbox': {
            alignSelf: 'center'
        }
    },
    header: {
        borderBottom: 'none',
        width: '100%'
    },
    headerContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    headerTitle: {
        alignSelf: 'center',
        fontSize: '1.4rem'
    },
    headerAction: {},
    editButton: {
        minWidth: '25px',
        maxWidth: '25px',
        margin: '4px 8px 8px 0',
        color: theme.palette.primary.main,
        border: 'none',
        '&:hover': {
            border: 'none',
            backgroundColor: '#ffffff00',
            color: '#42de11'
        },
        [theme.darkTheme]: {
            backgroundColor: '#0000',
            '&:hover': {
                border: 'none',
                color: '#42de11',
                backgroundColor: '#0000'
            }
        }
    },
    deleteButton: {
        minWidth: '25px',
        maxWidth: '25px',
        margin: '4px 8px 8px 0',
        color: '#e34843',
        border: 'none',
        '&:hover': {
            border: 'none',
            backgroundColor: '#ffffff00',
            color: '#ec110a'
        },
        [theme.darkTheme]: {
            backgroundColor: '#0000',
            '&:hover': {
                border: 'none',
                color: '#ec110a',
                backgroundColor: '#0000'
            }
        }
    },
    grid: {
        marginTop: 16,
        padding: 0,
        '& .p-datatable-thead tr:only-child th': {
            height: 57
        }
    }
});

const TYPE_TRANSLATED = <Translate>Translations</Translate>;

const TRANSLATIONS = {
    PageTitle: '',
    AddBtn: (
        <>
            <Translate>Add New</Translate>
        </>
    ),
    CancelBtn: <Translate>Cancel</Translate>,
    SaveBtn: <Translate>Save</Translate>,
    DeleteBtn: <Translate>Delete</Translate>,
    ConfirmAcceptBtn: <Translate>Submit</Translate>,
    ConfirmRejectBtn: <Translate>Cancel</Translate>,
    addEditText: (
        <>
            <Translate>Add New</Translate> {TYPE_TRANSLATED}
        </>
    ),
    removeIllegalChars: <Translate>Please Remove Illegal Characters</Translate>,
    useLargerValue: <Translate>Please Use a Larger Value</Translate>,
    useSmallerValue: <Translate>Please Use a Smaller Value</Translate>,
    requiredField: <Translate>Required Field empty</Translate>,
    correctInput: <Translate>Please Correct Input</Translate>,
    addValidEmail: <Translate>Please Add a Valid Email</Translate>,
    addValidUserId: <Translate>Please Add a Valid User Id</Translate>,
    requiredLengthTooSmall: <Translate>Required Length Too Small</Translate>,
    requiredLengthTooLarge: <Translate>Required Length Too Large</Translate>,
    changeTypeConfirmTitle: (
        <>
            <Translate>Are you sure you want to change</Translate> {TYPE_TRANSLATED}
        </>
    ),
    addNewTypeConfirmTitle: (
        <>
            <Translate>Are you sure you want to add new</Translate> {TYPE_TRANSLATED}
        </>
    ),
    deleteTypeConfirmTitle: (
        <>
            <Translate>Are you sure you want to delete</Translate> {TYPE_TRANSLATED}
        </>
    ),
    addTypeConfirmTitle: (
        <>
            <Translate>Are you sure you want to add</Translate> {TYPE_TRANSLATED}
        </>
    ),
    editDialogText: (data) => {
        if (data.length !== 3) {
            return <Translate>Are you sure you want to change</Translate>;
        }

        return `${(<Translate>Are you sure you want to change</Translate>)} ${data[0]}: "${data[1]}" ${(<Translate>to</Translate>)} "${data[2]}"?`;
    },
    addNewTypeDialog: (data) => {
        if (data.length !== 2) {
            return <Translate>Are you sure you want to add new</Translate>;
        }

        return `${(<Translate>Are you sure you want to add new</Translate>)} ${data[0]} ${(<Translate>type</Translate>)}: "${data[1]}"?`;
    }
};

const cellFormatter = (value) => {
    return (
        <DrcTooltip tipText={value || ''}>
            <span>{value || ''}</span>
        </DrcTooltip>
    );
};

const columns = [
    {
        key: 'DisplayName',
        name: 'DisplayName',
        isDisabled: true,
        filter: true,
        columnTemplate: (row) => cellFormatter(row.DisplayName)
        //IsUnique: true
    },
    {
        key: 'English',
        name: 'English',
        isDisabled: true,
        filter: true,
        columnTemplate: (row) => cellFormatter(row.English)
    },
    {
        key: 'Spanish',
        name: 'Spanish',
        isRequired: false,
        filter: true,
        columnTemplate: (row) => cellFormatter(row.Spanish)
    }
];

class ManageTranslations extends Component {
    state = {
        isEnabled: false,
        data: [],
        dialogAddNewOpen: false,
        dialogEditOpen: false,
        deleteData: [],
        open: false,
        rowSelect: true,
        selectionMode: 'multiple',
        selected: [],
        typeAheadString: '',
        optionSelected: null
    };

    GetAllTranslations = async () => {
        await this.props.authService.getAccessToken().then(async (token) => {
            await Middleware.Send('Translations', token, APIEndPoints.TRANSLATION, 'GET', '', middlewareConfig(true, true))
                .then((response) => {
                    let translations = this.makeTranslations(response);
                    this.setState({ data: translations });
                })
                .catch((error) => {
                    console.log('error');
                    throw error;
                });
        });
    };

    makeTranslations = (data) => {
        console.log(data);
        var translations = {};
        data.forEach((record) => {
            let English = record.DisplayName;
            let Spanish = record.Language === 'Spanish' ? record.Translation : '';
            if (translations[record.DisplayName]) {
                translations[record.DisplayName] = {
                    ...translations[record.DisplayName],
                    DisplayName: record.DisplayName,
                    English,
                    Spanish
                };
            } else {
                translations[record.DisplayName] = {
                    DisplayName: record.DisplayName,
                    English,
                    Spanish
                };
            }
        });

        // append local translations
        // why? so that if user wants to update any of these there will be an entry created in DB
        // and eventually we will have all data in DB
        Object.keys(this.props.rules).map((displayName) => {
            if (!translations[displayName]) {
                translations[displayName] = {
                    DisplayName: displayName,
                    English: displayName,
                    Spanish: this.props.rules[displayName][1] // the second element is spanish translation
                };
            }
            return null;
        });

        return Object.keys(translations).map((key) => translations[key]);
    };

    // Only allowing to update spanish for now
    postTranslation = async (oldRecord, updatedRecord) => {
        try {
            let payload = { DisplayName: oldRecord.DisplayName, Language: 'Spanish', Translation: updatedRecord.Spanish };
            let token = await this.props.authService.getAccessToken();
            await Middleware.Send('M_Translations', token, APIEndPoints.TRANSLATION, 'POST', payload, middlewareNoMappingConfig);
            this.props.showToast('Translation Updated Successfully', toastType.success);
        } catch (err) {
            this.props.setErrorsAction('API Error', `Error occurred while updating plots`, JSON.stringify(err));
        }
    };

    onEdit = (oldRow, newRow) => {
        this.postTranslation(oldRow, newRow);
    };

    async componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }

        // contact middleware here
        await this.GetAllTranslations().catch((error) => {
            console.log(error);
        });
    }

    render() {
        const { isMasterDataInitialized, classes } = this.props;
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        var uniqueKeyField = 'unique';
        var editable = true;
        var rowUnderEditUniqueKey = '';
        var onEditorValueChange = '';

        return (
            <DrcMain className={classes.main}>
                <DrcPageDataMaintenance
                    className={`${classes.gridStyles}`}
                    columns={columns}
                    data={this.state.data || []}
                    resultCount={(this.state.data || []).length}
                    type={TYPE_TRANSLATED}
                    textOptions={TRANSLATIONS}
                    settings={{
                        ActionColumnSetting: 1,
                        EnableExport: false,
                        EnableAdd: false,
                        EnableEdit: true,
                        EnableDelete: false
                    }}
                    updateRefresh={this.GetAllTranslations}
                    refresh={true}
                    onEdit={this.onEdit}
                    uniqueKeyField={uniqueKeyField}
                    rowUnderEditUniqueKey={rowUnderEditUniqueKey}
                    onEditorValueChange={onEditorValueChange}
                    editable={editable}
                    columnKey={'DisplayName'}
                />
            </DrcMain>
        );
    }
}

function mapStateToProps({ masterReducer, translationReducer }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        rules: translationReducer.rules
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    setErrorsAction: (title, error, errorstack) => dispatch(setErrorsAction(title, error, errorstack))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(ManageTranslations))));
