import {
  SET_LOCATIONS_DATA
} from '../actions/locationActions';

const initialState = {
  records: [],
  totalRecords: 0
};

const LocationReducer = (state = initialState, action) => {
  if (action.type === SET_LOCATIONS_DATA) {
    return Object.assign({}, state, {
      records: action.payload.data,
      totalRecords: action.payload.count
    });
  } else {
    return state;
  }
};

export default LocationReducer;
