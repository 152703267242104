export const SET_IMPORTED_FILE_DATA = 'SET_IMPORTED_FILE_DATA';
export const setImportedFileData = (data, berry) => ({
  type: SET_IMPORTED_FILE_DATA,
  payload: {data, berry}
});

export const SET_VALIDATION_DATA = 'SET_VALIDATION_DATA';
export const setValidationData = (data) => ({
  type: SET_VALIDATION_DATA,
  payload: data
});