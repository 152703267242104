import { Middleware } from 'one-ring';

const itemCodeMappings = [
    Middleware.CreateMapping('ItemNbr', 'ItemNbr'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('ItemId', 'ItemId'),
    Middleware.CreateArrayMapping('Results', 'ItemCodeList')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('itemCodeData', 'GET')) {
        Middleware.RegisterMapping('itemCodeData', 'GET', itemCodeMappings);
    }
}

const itemCodeData = {
    ConfigureMiddleware
};

export default itemCodeData;
