import { Middleware } from 'one-ring';

const createAttributeMapping = [
    Middleware.CreateMapping('attributeId', 'AttributeId'),
    Middleware.CreateMapping('attributeName', 'AttributeName'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('attributeDisplayName', 'DisplayName'),
    Middleware.CreateMapping('attributeDescription', 'Description'),
    Middleware.CreateMapping('language', 'Language'),
    Middleware.CreateMapping('dataType', 'Datatype'),
    Middleware.CreateMapping('referenceId', 'ReferenceId'),
    Middleware.CreateMapping('lookup', 'Lookup'),
    Middleware.CreateMapping('createdBy', 'CreatedBy'),
    Middleware.CreateMapping('createdDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('modifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('modifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('entityName', 'EntityName'),
    Middleware.CreateMapping('key', 'LookupText'),
    Middleware.CreateMapping('value', 'LookupValue'),
    Middleware.CreateMapping('lookupOrder', 'LookupOrder'),
    Middleware.CreateMapping('LookupId', 'LookupId'),
    Middleware.CreateArrayMapping('Data', 'Data'),
    // Middleware.CreateMapping('id', 'LookupOrder'),
    // Error Fields
    Middleware.CreateMapping('status', 'Status'),
    Middleware.CreateMapping('errorMessage', 'ErrorMessage'),
    Middleware.CreateMapping('errorType', 'ErrorType'),
    Middleware.CreateMapping('IsLocked', 'IsLocked')

];

const getAttributeMapping = [
    Middleware.CreateMapping('attributeId', 'AttributeId'),
    Middleware.CreateMapping('attributeName', 'AttributeName'),
    Middleware.CreateMapping('createdBy', 'CreatedBy'),
    Middleware.CreateMapping('createdDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('dataType', 'Datatype'),
    Middleware.CreateMapping('attributeDescription', 'Description'),
    Middleware.CreateMapping('attributeDisplayName', 'DisplayName'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('language', 'Language'),
    Middleware.CreateArrayMapping('lookupValues', 'Lookup'),
    Middleware.CreateMapping('modifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('modifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('referenceId', 'ReferenceId'),
    Middleware.CreateMapping('entityName', 'EntityName'),
    Middleware.CreateMapping('key', 'LookupName'),
    Middleware.CreateMapping('value', 'LookupValue'),
    Middleware.CreateMapping('LookupId', 'LookupId'),
    Middleware.CreateMapping('order', 'Order')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Attribute', 'GET')) {
        Middleware.RegisterMapping('Attribute', 'GET', getAttributeMapping);
        Middleware.RegisterMapping('Attribute', 'POST', createAttributeMapping);
    }
}

const Attribute = {
    ConfigureMiddleware
};

export default Attribute;
