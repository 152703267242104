import { SET_ERRORS, INVERT_MODAL_BOOLEANS } from '../actions/actions';

const initialState = {
    showStatusModal: false,
    showConfirmModal: false,
    showHeaderModal: false,
    showDetailsErrorModal: false,
    headerModalValues: {
        ItemType: "",
        ItemCode: "",
        FootprintCode: "",
        Blocked: "",
        BlockedReason: "",
        CasePerLevel: "",
        Length: "",
        Width: "",
        Height: "",
        PalletStackHeight: "",
        DefaultUnitType: "",
        StackMethod: "",
        LevelUnits: "",
        IsDefault: null,
        CreatedBy: "",
        CreatedDateTime: "",
        ModifiedDateTime: "",
        ModifiedBy: "",
    },
    showModal: false,
    settings: {
        isEdit: false,
        ItemId: -1,
        title: 'Add New Attribute',
        berryType: { label: '', value: '' },
        variety: { label: '', value: '' },
        varietyName: '',
        plantType: { label: '', value: '' },
        field: { label: '', value: '' },
        inventory: { label: '', value: '' }
    },
    errors: {
        berryType: false,
        variety: false,
        plantType: false,
        field: false,
        inventory: false
    }
};

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERRORS:
            return Object.assign({}, state, {
                errors: action.payload.errors
            });
        case INVERT_MODAL_BOOLEANS:
            return Object.assign({}, state, {
                [action.payload.name]: !state[action.payload.name]
            });
        default:
            return state;
    }
};

export default modalReducer;
