export const SET_RANCHES = 'SET_RANCHES';
export const setRanches = (data) => ({
    type: SET_RANCHES,
    payload: data
});

export const SET_TREATMENT_TYPES = 'SET_TREATMENT_TYPES';
export const setTreatmentTypes = (data) => ({
    type: SET_TREATMENT_TYPES,
    payload: data
});

export const SET_VARIETY = 'SET_VARIETY';
export const setVariety = (data) => ({
    type: SET_VARIETY,
    payload: data
});

export const SET_BERRY_TYPE = 'SET_BERRY_TYPE';
export const setBerryType = () => ({
    type: SET_BERRY_TYPE
});
