import { Middleware } from 'one-ring';

const applicationIdsListMapping = [Middleware.CreateArrayMapping('Results', 'Results')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ApplicationIds', 'GET')) {
        Middleware.RegisterMapping('ApplicationIds', 'GET', applicationIdsListMapping);
    }

    if (!Middleware.CheckMappingExists('BlockAppId', 'GET')) {
        Middleware.RegisterMapping('BlockAppId', 'GET', applicationIdsListMapping);
    }
}

const applicationIdsMapping = {
    ConfigureMiddleware
};

export default applicationIdsMapping;
