import { SET_ATTRIBUTE_LIST, SET_ATTRIBUTE_TO_UPDATE } from '../actions/attributeActions';

const initialState = {
    records: [],
    attributeToUpdate: {},
};

const AttributeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ATTRIBUTE_LIST:
            return Object.assign({}, state, {
                records: action.payload
            });
        case SET_ATTRIBUTE_TO_UPDATE:
            return Object.assign({}, state, {
                attributeToUpdate: action.payload
            });
        default:
            return state;
    }
};

export default AttributeReducer;
