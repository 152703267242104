const english = {
    oopsJustKidding: 'Oops Just Kidding',
    areYouSureYouWantToDelete: 'Are you sure you want to delete',
    advanced: 'Advanced',
    series: 'Series',
    species: 'Species',
    comments: 'Comments',
    floweringType: 'Flowering Type',
    overallStatus: 'Overall Status',
    seedlingPlant: 'Seedling Plant',
    seedlingYear: 'Seedling Year',
    srcBreedingProgram: 'Source Breeding Program',
    proprietary: 'Proprietary',
    berryType: 'Berry Type',
    createDate: 'Create Date',
    createdBy: 'Created By',
    modifiedBy: 'Modified By',
    modifiedTime: 'Modified Time',
    modifiedDate: 'Modified Date',
    defaultLanguage: 'Default Language',
    isActive: 'Is Active',
    active: 'Active',
    tableName: 'Table Name',
    activeStatus: 'Active Status',
    entity: 'Entity',
    date: 'Date',
    downloadTemplate: 'Download Template',
    download: 'Download',
    refresh:'Refresh',
    import: 'Import',
    newSelection: 'New Selection',
    actions: 'Actions',
    entities: 'Entities',
    newEntity: 'New Entity',
    attributes: 'Attributes',
    attribute: 'Attribute',
    language: 'Language',
    createAttribute: 'Create Attribute',
    createEntity: 'Create Entity',
    addNewAttribute: 'New Attribute',
    attributeName: 'Attribute Name',
    attributeDesc: 'Attribute Description',
    displayName: 'Display Name',
    datatype: 'Datatype',
    lookupValues: 'Lookup Values',
    create: 'Create',
    backToEntities: 'Back To Entities',
    backToAttributes: 'Back To Attributes',
    add: 'Add',
    save: 'Save',
    cancel: 'Cancel',
    key: 'Key',
    value: 'Value',
    isSearchable: 'IsSearchable',
    isUnique: 'IsUnique',
    isConditional: 'IsConditional',
    description: 'Description',
    fieldName: 'Field Name',
    accessType: 'Access Type',
    adGroups: 'AD Groups',
    done: 'Done',
    edit: 'Edit',
    delete: 'Delete',
    accessControl: 'Access Control',
    entityName: 'Entity Name',
    fields: 'Fields',
    newField: 'New Field',
    dependsOn: 'Depends On',
    values: 'Values',
    chooseExcel: 'Choose Excel File',
    dropExcel: 'or drop excel file here',
    stopProcessing: 'Stop Processing',
    continue: 'Continue',
    close: 'Close',
    submit: 'Submit',
    errors: 'Errors',
    missingHeaders: 'Missing Headers',
    uploadSelection: 'Upload Selection',
    missingColumns: 'Missing Columns',
    headerMissingMsg: 'Below column names are missing',
    chooseSheet: 'Please choose which sheet to use',
    applyFilter: 'Apply Filter',
    resetFilter: 'Reset Filter',
    moreFilters: 'More Filters',
    lessFilters: 'Less Filters',
    showFilter: 'Filter',
    hideFilter: 'Hide Filter',
    selectOption: 'Select Option',
    isRequired: 'IsRequired',
    confirm: 'Confirm',
    location: 'Location',
    newLocation: 'New Location',
    uploadLocation: 'Upload Location',
    selections: 'Selections',
    selection: 'Selection',
    plots: 'Plots',
    locations: 'Locations',
    others: 'Others',
    metadata: 'Metadata',
    masterdata: 'Masterdata',
    footprint: 'Footprint',
    history: 'History',
    newPlot: 'New Plot',
    uploadPlots: 'Upload Plots',
    fieldCode: 'Field Code',
    enterFieldCode: 'Enter field code',
    plantingYear: 'Planting Year',
    ranch: 'Ranch',
    ranchName: 'Ranch Name',
    trialType: 'Trial Type',
    advancementGroup: 'Advancement Group',
    enterPurpose: 'Enter Purpose',
    costCenter: 'Cost Center',
    plantingSeason: 'Planting Season',
    organic: 'Organic',
    substrate: 'Substrate',
    factors: 'Factors',
    selectAll: 'Select All',
    clearAll: 'Clear All',
    reverseAll: 'Reverse All',
    cannotAddNew: 'Cannot add new record',
    noRecords: 'No records to display',
    welcome: 'Welcome',
    listFootPrint: 'List Footprint',
    addfootprint: 'Add Footprint',
    itemFootprint: 'Item Footprint',
    itemFootprintUpper: 'ITEM FOOTPRINT',
    diffDataInOnePlace: 'Different entities data at one place',
    masterdataUpper: 'MASTERDATA',
    metadataUpper: 'METADATA',
    metadataTileDescrip: 'All attributes & entities related data',
    translations: 'Translations',
    releases: 'Releases',
    logout: 'LogOut',
    update: 'Update',
    plotUpdate: 'Plots Update',
    selectStatus: 'Select new status',
    translation: 'Translation',
    addNew: 'Add New',
    removeIllegalChars: 'Please Remove Illegal Characters',
    useLargerValue: 'Please Use a Larger Value',
    useSmallerValue: 'Please Use a Smaller Value',
    requiredField: 'Required Field empty',
    correctInput: 'Please Correct Input',
    addValidEmail: 'Please Add a Valid Email',
    addValidUserId: 'Please Add a Valid User Id',
    requiredLengthTooSmall: 'Required Length Too Small',
    requiredLengthTooLarge: 'Required Length Too Large',
    addEditText: 'Add new',
    changeTypeConfirmTitle: 'Are you sure you want to change',
    addNewTypeConfirmTitle: 'Are you sure you want to add new',
    deleteTypeConfirmTitle: 'Are you sure you want to delete',
    addTypeConfirmTitle: 'Are you sure you want to add',
    editDialogText1: 'Are you sure you want to change',
    editDialogText2: 'to',
    addNewTypeDialog1: 'Are you sure you want to add new',
    addNewTypeDialog2: 'type'
};

export default english;
