import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { DrcButton } from 'driscolls-react-components';

const styles = (theme) => ({
    main: {
        boxShadow: '1px 1px 8px 3px #d6d6d6',
        padding: '1rem',
        minWidth: '250px',
        maxWidth: '340px',
        borderRadius: '2px',
        minHeight: '50px',
        flex: 1,
        margin: '1rem',
        [theme.darkTheme]: {
            boxShadow: 'none',
            border: 'solid 1px #222'
        }
    },
    head: {
        display: 'grid',
        gridTemplateAreas: `
          "icon header"
          "icon details"
        `
    },
    icon: {
        gridArea: 'icon',
        marginRight: '6px'
    },
    header: {
        fontSize: '1.5rem',
        gridArea: 'header'
    },
    details: {
        gridArea: 'details',
        [theme.darkTheme]: {
            color: '#aaa'
        }
    },
    body: {
        marginTop: '1rem'
    },
    btn: {
        margin: '0 !important',
        textDecoration: 'underline',
        border: 'none',
        '&:hover': {
            border: 'none'
        }
    },
    iconBtn: {
        height: '3rem',
        minWidth: '3rem',
        padding: '1.5rem',
        boxShadow: '1px 1px 4px 1px #d6d6d6',
        margin: '0px 8px 8px 0px !important',
        textDecoration: 'none',
        [theme.darkTheme]: {
            boxShadow: 'none',
            border: 'solid 1px #222'
        }
    }
});

class LandingPageTile extends Component {
    render() {
        const { classes, icon, header, details, links, color } = this.props;
        return (
            <div className={classes.main}>
                <div className={classes.head}>
                    <div className={classes.icon}>{icon}</div>
                    <div className={classes.header} style={{ color: `${color}` }}>
                        {header}
                    </div>
                    <div className={classes.details}>{details}</div>
                </div>
                <div className={classes.body}>
                    {links &&
                        links.map((link) => (
                            <DrcButton
                                isSecondary
                                className={`${classes.btn} ${link.icon ? classes.iconBtn : ''}`}
                                style={{ color: `${color}` }}
                                onClick={link.handler}
                            >
                                <div>{link.icon}</div>
                                {link.text && <div style={{ padding: '0px 0px 4px 4px' }}>{link.text}</div>}
                            </DrcButton>
                        ))}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(LandingPageTile);
