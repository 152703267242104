import { Middleware } from 'one-ring';

const translationsMapping = [
    Middleware.CreateMapping('TranslationId', 'TranslationId'),
    Middleware.CreateMapping('DisplayName', 'DisplayName'),
    Middleware.CreateMapping('Translation', 'Translation'),
    Middleware.CreateMapping('Language', 'Language'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Translations', 'GET')) {
        Middleware.RegisterMapping('Translations', 'GET', translationsMapping);
    }
}

const translationMiddleware = {
    ConfigureMiddleware
};

export default translationMiddleware;
