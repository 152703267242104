export const SET_PLOTS_HISTORY_DATA = 'SET_PLOTS_HISTORY_DATA';
export const setPlotsHistoryData = (records, count) => ({
    type: SET_PLOTS_HISTORY_DATA,
    payload: { records, count }
});

export const SET_SELECTION_HISTORY_DATA = 'SET_SELECTION_HISTORY_DATA';
export const setSelectionHistoryData = (records, count) => ({
    type: SET_SELECTION_HISTORY_DATA,
    payload: { records, count }
});