import { Middleware } from 'one-ring';

const languageOptionsMapping = [
    Middleware.CreateMapping('LanguageId', 'LanguageId'),
    Middleware.CreateMapping('LanguageName', 'LanguageName'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('isActive', 'isActive'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateArrayMapping('Results'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('LanguageOptions', 'GET')) {
        Middleware.RegisterMapping('LanguageOptions', 'GET', languageOptionsMapping);
    }
}

const languageOptions = {
    ConfigureMiddleware
};

export default languageOptions;
