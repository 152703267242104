export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const setIsAdmin = (data) => ({
    type: SET_IS_ADMIN,
    payload: data
});

export const SET_READONLY_ACCESS = 'SET_READONLY_ACCESS';
export const setIsReadOnly = (data) => ({
    type: SET_READONLY_ACCESS,
    payload: data
});
