// these wont be required, using them for now
import English from '../data/json/english';
import Spanish from '../data/json/spanish';

const makeRules = () =>
    Object.keys(English).reduce((rules, key) => {
        rules[English[key]] = [English[key], Spanish[key]];
        return rules;
    }, {});

const makeRulesFromAPI = (translations) =>
    translations.reduce((rule, record) => {
        let spanish = record.Language === 'Spanish' ? record.Translation : record.DisplayName;
        spanish = spanish ? spanish.trim() : spanish;
        // if spanish translation is not present, skip the record
        if(!!spanish){
            rule[record.DisplayName] = [record.DisplayName, spanish, record.DisplayName, record.DisplayName];
        }
        return rule;
    }, {});

export const ADD_TO_PENDING_TRANSLATIONS = 'ADD_TO_PENDING_TRANSLATIONS';
export const addUnTranslatedKey = (key) => ({
    type: ADD_TO_PENDING_TRANSLATIONS,
    payload: key
});

export const CLEAR_PENDING_TRANSLATIONS = 'CLEAR_PENDING_TRANSLATIONS';
export const clearPendingTranslations = () => ({
    type: CLEAR_PENDING_TRANSLATIONS,
    payload: ''
});

export const SET_TRANSLATION_RULE = 'SET_TRANSLATION_RULE';
export const setTranslationRules = (translations) => ({
    type: SET_TRANSLATION_RULE,
    payload: { ...makeRules(), ...makeRulesFromAPI(translations) }
});

export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const setPreferredLanguage = (language) => ({
    type: SET_USER_LANGUAGE,
    payload: language
});
