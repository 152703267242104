import { SET_RANCHES, SET_TREATMENT_TYPES, SET_VARIETY, SET_BERRY_TYPE } from '../actions/blockAppMappingActions';

const initialState = {
    ranches: [],
    treatmentTypes: [],
    variety: [],
    berryTypes: []
};

const BlockAppMappingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BERRY_TYPE:
            const berryTypes = [
                { label: 'BLACK', value: 1 },
                { label: 'BLUE', value: 2 },
                { label: 'RASP', value: 3 }
            ];
            return Object.assign({}, state, {
                berryTypes
            });
        case SET_RANCHES:
            let ranchOptions = action.payload.map((record) => {
                return { label: record.RanchNbr, value: record.RanchNbr };
            });
            return Object.assign({}, state, {
                ranches: action.payload,
                ranchOptions
            });
        case SET_TREATMENT_TYPES:
            let treatmentTypeOptions = action.payload.map((record) => {
                return { label: record.Treatment, value: record.TreatmentTypeId,BerryType:record.BerryType};
            });
            return Object.assign({}, state, {
                treatmentTypes: action.payload,
                treatmentTypeOptions
            });
        case SET_VARIETY:
            let varietyOptions = action.payload.map((record) => {
                return { code: record.Code, descript: record.Descript, label: record.Descript, value: record.Code };
            });
            return Object.assign({}, state, {
                variety: action.payload,
                varietyOptions
            });
        default:
            return state;
    }
};

export default BlockAppMappingReducer;
