import { Middleware } from 'one-ring';

const othersListMapping = [
  Middleware.CreateMapping('Results', 'Results'),
];

function ConfigureMiddleware() {
  if (!Middleware.CheckMappingExists('Others', 'GET')) {
    Middleware.RegisterMapping('Others', 'GET', othersListMapping);
    Middleware.RegisterMapping('Others', 'POST', othersListMapping);
  }
}

const othersList = {
  ConfigureMiddleware
};

export default othersList;
