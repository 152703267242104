import { Middleware } from 'one-ring';

const attributesMapping = [
    Middleware.CreateMapping('AttributeId', 'AttributeId'),
    Middleware.CreateMapping('AttributeName', 'AttributeName'),
    Middleware.CreateMapping('Datatype', 'Datatype'),
    Middleware.CreateMapping('isActive', 'isActive'),
    Middleware.CreateMapping('ReferenceId', 'ReferenceId'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('IsLocked', 'IsLocked'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Attributes', 'GET')) {
        Middleware.RegisterMapping('Attributes', 'GET', attributesMapping);
    }
}

const attributes = {
    ConfigureMiddleware
};

export default attributes;
