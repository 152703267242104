import { applyMiddleware, compose, createStore } from 'redux';
import monitorReducersEnhancer from './monitorReducers';
import loggerMiddleware from './logger';
import rootReducer from '../reducers';

export var Store = configureStore();

function configureStore(preloadedState) {
    const middlewares = [loggerMiddleware]; //thunkMiddleware
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const composedEnhancers = composeEnhancers(...enhancers);

    const store = createStore(rootReducer, preloadedState, composedEnhancers);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('../reducers', () => store.replaceReducer(rootReducer));
    }

    return store;
}
