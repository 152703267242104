
export const SET_ENTITY_FILTER = 'SET_ENTITY_FILTER';
export const setEntityFilter = (filters) => ({
    type: SET_ENTITY_FILTER,
    payload: filters
});

export const CLEAR_ENTITY_DATA = 'CLEAR_ENTITY_DATA';
export const clearEntityData = () => ({
    type: CLEAR_ENTITY_DATA,
    payload: null
});

export const SET_ENTITY_DATA = 'SET_ENTITY_DATA';
export const setEntityData = (records, count) => ({
    type: SET_ENTITY_DATA,
    payload: { records, count }
});

export const SET_FILTERED_ENTITY_DATA = 'SET_FILTERED_ENTITY_DATA';
export const setFilteredEntityData = (records, count) => ({
    type: SET_FILTERED_ENTITY_DATA,
    payload: { records, count }
});

export const SET_ENTITY_METADATA = 'SET_ENTITY_METADATA';
export const setEntityMetaData = (data) => ({
    type: SET_ENTITY_METADATA,
    payload: data
});

export const SET_ENTITY_FILTERS = 'SET_ENTITY_FILTERS';
export const setEntityFilters = (filters) => ({
    type: SET_ENTITY_FILTERS,
    payload: filters
});
