import { SET_ITEM_TYPE_FILTER, CLEAR_ONE_ATTR_FILTER } from '../../../actions/actions';

const initialState = {
    footprintFilters: {
        IsDefault: { label: '', value: '', index: -2, isSet: false },
        Blocked: { label: '', value: '', index: -3, isSet: false },
        ItemType: { label: '', value: '', index: -4, isSet: false },
        ItemCode: { label: '', value: '', index: -5, isSet: false },
    },
    footprintFilterOptions: {
        IsDefault: [],
        Blocked: [],
        ItemType: [
            { value: 'FRUIT', label: 'Fruit' },
            { value: 'PKG', label: 'Packaging' },
            { value: 'MISC', label: 'Misc.' },
            { value: 'PLANT', label: 'Plants' },
        ],
        ItemCode: [],
    },
    filters: {
        BerryType: { label: '', value: '', index: -2, isSet: false },
        Variety: { label: '', value: '', index: -3, isSet: false },
        PlantType: { label: '', value: '', index: -4, isSet: false },
        HasAttribute: { label: '', value: '', index: -5, isSet: false }
    },
    ItemTypeDropdown: { value: "FRUIT", label: "Fruit" }
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ITEM_TYPE_FILTER:
            return {
                ...state,
                [action.payload.name]: action.payload.option
            };
        case CLEAR_ONE_ATTR_FILTER:
            var filters = {};
            Object.entries(state.filters).forEach((filter) => {
                if (state.filters[action.payload.filter].index < filter[1].index) {
                    filters[filter[0]] = { ...filter[1], index: filter[1].index - 1 };
                } else {
                    filters[filter[0]] = filter[1];
                }
            });
            return {
                ...state,
                filters: {
                    ...filters,
                    [action.payload.filter]: initialState.filters[action.payload.filter]
                }
            };
        default:
            return state;
    }
};

export default filterReducer;
