import {
    REASON_CODES,
    STATUSES,
    SET_GRID_DATA,
    SET_SELECTED_FOOTPRINT,
    SET_ITEM_CODE_OPTIONS,
    SET_FP_CODE_OPTIONS,
    ADD_FP_CODE_OPTION,
    SET_LANGUAGE_OPTIONS,
    SET_MASTER_DATA_INITIALIZED,
    SET_MASTER_DATA_REDIRECT_URL,
    SET_DATATYPE_LIST,
    SET_ATTRIBUTE_OPTIONS_LIST,
    SET_ENTITY_OPTIONS_LIST,
    SET_GROUP_OPTIONS_LIST,
    SET_BERRY
} from '../actions/MasterActions';

const REDIRECT_URL_KEY = 'REDIRECT_URL';
const DEFAULT_REDIRECT_URL = '/Apps/';
const REDIRECT_URL = sessionStorage.getItem(REDIRECT_URL_KEY) || DEFAULT_REDIRECT_URL;

const initialState = {
    selectedFootprint: {},
    footprintLineData: [],
    footprintHeaderData: [],
    itemCodeOptions: [],
    FPCodeOptions: [],
    FPCodesNotAllowed: [],
    reasonCodes: [],
    statuses: [],
    languages: [],
    redirectUrl: REDIRECT_URL,
    attributeList: [],
    dataTypeList: [],
    entityList: [],
    groups: [],
    berry: localStorage.getItem('selectedBerry') || 'STRAW'
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case REASON_CODES:
            return Object.assign({}, state, {
                reasonCodes: action.payload
            });
        case STATUSES:
            return Object.assign({}, state, {
                statuses: action.payload
            });
        case SET_GRID_DATA:
            return Object.assign({}, state, {
                [action.payload.name]: action.payload.data
            });
        case SET_SELECTED_FOOTPRINT:
            return Object.assign({}, state, {
                selectedFootprint: { ...action.payload.rowKey }
            });
        case SET_ITEM_CODE_OPTIONS:
            var selectItemOptions = (action.payload.options || []).map((entry) => {
                return { value: entry.ItemNbr, label: entry.ItemNbr, ItemId: entry.ItemId };
            });

            return Object.assign({}, state, {
                itemCodeOptions: selectItemOptions
            });
        case SET_FP_CODE_OPTIONS:
            var rejectedArray = [],
                alreadyAdded = [],
                selectOptions = [];
            (action.payload.options || []).forEach((entry) => {
                if (action.payload.itemCode === entry.ItemCode) {
                    rejectedArray.push(entry.FootprintCode);
                }
                if (!alreadyAdded.includes(entry.FootprintCode)) {
                    alreadyAdded.push(entry.FootprintCode);
                    selectOptions.push({ value: entry.FootprintCode, label: entry.FootprintCode });
                }
            });
            selectOptions = selectOptions.filter((option) => !rejectedArray.includes(option.value));

            return Object.assign({}, state, {
                FPCodeOptions: selectOptions,
                FPCodesNotAllowed: rejectedArray
            });
        case ADD_FP_CODE_OPTION:
            return Object.assign({}, state, {
                FPCodeOptions: [action.payload.option, ...state.FPCodeOptions]
            });
        case SET_LANGUAGE_OPTIONS:
            return Object.assign({}, state, {
                languages: action.payload
            });
        case SET_MASTER_DATA_REDIRECT_URL:
            if (action.payload === '/' || action.payload === '/implicit/callback' || action.payload === '/InitializeApplication/') {
                return state;
            }

            sessionStorage.setItem(REDIRECT_URL_KEY, action.payload);

            return Object.assign({}, state, {
                redirectUrl: action.payload
            });
        case SET_MASTER_DATA_INITIALIZED:
            return Object.assign({}, state, {
                isInitialized: action.payload
            });
        case SET_DATATYPE_LIST:
            let dataTypeList = action.payload.map((e) => {
                e.label = e.Datatype;
                e.value = e.Datatype;
                return e;
            });

            return Object.assign({}, state, {
                dataTypeList
            });
        case SET_ATTRIBUTE_OPTIONS_LIST:
            let attributeList = action.payload.filter(e => e.isActive).map((e) => {
                e.label = e.AttributeName;
                e.value = e.AttributeName;
                return e;
            });

            return Object.assign({}, state, {
                attributeList
            });
        case SET_ENTITY_OPTIONS_LIST:
            let entityList = action.payload.filter(e => e.IsActive).map((e) => {
                e.label = e.EntityName;
                e.value = e.EntityName;
                return e;
            });

            return Object.assign({}, state, {
                entityList
            });
        case SET_GROUP_OPTIONS_LIST:
            let groups = action.payload.map((e) => {
                e.label = e.RoleName;
                e.value = e.RoleName;
                return e;
            });

            return Object.assign({}, state, {
                groups
            });
        case SET_BERRY:
            return Object.assign({}, state, {
                berry: action.payload
            });
        default:
            return state;
    }
};

export default masterReducer;
