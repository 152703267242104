import {
  SET_OTHERS_DATA
} from '../actions/othersActions';

const initialState = {
  records: [],
  totalRecords: 0
};

const OthersReducer = (state = initialState, action) => {
  if (action.type === SET_OTHERS_DATA) {
    return Object.assign({}, state, {
      records: action.payload.data,
      totalRecords: action.payload.count
    });
  } else {
    return state;
  }
};

export default OthersReducer;
