import {
    SET_PLOTS_HISTORY_DATA,
    SET_SELECTION_HISTORY_DATA
} from '../actions/historyActions';

const initialState = {
    plotRecords: [],
    plotTotalRecords: 0,
    selectionRecords: [],
    selectionTotalRecords: 0
};

const HistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PLOTS_HISTORY_DATA:
            return Object.assign({}, state, {
                plotRecords: action.payload.records,
                plotTotalRecords: action.payload.count
            });
        case SET_SELECTION_HISTORY_DATA:
            return Object.assign({}, state, {
                selectionRecords: action.payload.records,
                selectionTotalRecords: action.payload.count
            });
        default:
            return state;
    }
};

export default HistoryReducer;
