const spanish = {
    areYouSureYouWantToDelete: '¿Estás seguro de que quieres eliminar',
    oopsJustKidding: 'Cancelar',
    advanced: 'Avanzada',
    series: 'Serie',
    species: 'Especies',
    comments: 'Comentario',
    floweringType: 'Tipo de floración',
    overallStatus: 'Sstado general',
    srcBreedingProgram: 'Source Breeding Program',
    seedlingPlant: 'planta de semillero',
    seedlingYear: 'año de plántula',
    proprietary: 'Propiedad',
    berryType: 'Tipo de Baya',
    createDate: 'Fecha de Creación',
    createdBy: 'Creado Por',
    modifiedBy: 'Modificado por',
    modifiedTime: 'Hora Modificada',
    modifiedDate: 'Fecha de modificación',
    defaultLanguage: 'Idioma Predeterminado',
    isActive: 'Está activo',
    active: 'Activo',
    tableName: 'nombre de la tabla',
    activeStatus: 'Estado Activo',
    entity: 'Entidad',
    date: 'Fecha',
    downloadTemplate: 'Descargar plantilla',
    download: 'Descargar',
    refresh:'Actualizar',
    import: 'Importar',
    newSelection: 'Nueva Seleccion',
    actions: 'Comportamiento',
    entities: 'Entidades',
    newEntity: 'Entidad Nueva',
    attributes: 'Atributos',
    attribute: 'Atributo',
    language: 'Idioma',
    createAttribute: 'Crear Atributo',
    addNewAttribute: 'Nueva Atributo',
    attributeName: 'Nombre del Atributo',
    attributeDesc: 'Descripción del atributo',
    displayName: 'Nombre Para Mostrar',
    datatype: 'Tipo de datos',
    lookupValues: 'Valores de búsqueda',
    create: 'Crear',
    backToEntities: 'Volver a Entidades',
    backToAttributes: 'Volver a atributos',
    add: 'Añadir',
    save: 'Guardar',
    cancel: 'Cancelar',
    key: 'Llave',
    value: 'Valor',
    isSearchable: 'SePuedeBuscar',
    isUnique: 'EsUnico',
    isConditional: 'EsCondicional',
    description: 'Descripción',
    fieldName: 'Nombre del campo',
    accessType: 'Tipo de acceso',
    adGroups: 'Grupos de AD',
    done: 'Hecho',
    edit: 'Editar',
    delete: 'Eliminar',
    accessControl: 'Control de acceso',
    entityName: 'Nombre de la entidad',
    fields: 'Campos',
    newField: 'Nuevo campo',
    dependsOn: 'Depende de',
    values: 'Valores',
    chooseExcel: 'Elije archivo de Excel',
    dropExcel: 'o suelte el archivo de Excel aquí',
    stopProcessing: 'Detiene el procesamiento',
    continue: 'Seguir',
    close: 'Cerrar',
    submit: 'Enviar',
    errors: 'Errores',
    missingHeaders: 'Encabezados faltantes',
    uploadSelection: 'Subir selección',
    missingColumns: 'Columnas faltantes',
    headerMissingMsg: 'Faltan los nombres de las columnas siguientes',
    chooseSheet: 'Elije hoja para usar',
    applyFilter: 'Aplica filtros',
    resetFilter: 'Restablecer filtros',
    moreFilters: 'Más filtros',
    lessFilters: 'Menos filtros',
    showFilter: 'Filtros',
    hideFilter: 'Esconde filtros',
    selectOption: 'Selecciona una opción',
    isRequired: 'Es necesario',
    confirm: 'Confirmar',
    location: 'Ubicación',
    newLocation: 'Nueva ubicacion',
    uploadLocation: 'Cargar ubicación',
    createEntity: 'Crear entidad',
    selections: 'Selecciones',
    selection: 'Seleccione',
    plots: 'Parcelas',
    locations: 'Ubicaciones',
    others: 'Otros',
    metadata: 'Metadatos',
    masterdata: 'Datos maestros',
    footprint: 'Huella',
    history: 'Historia',
    newPlot: 'Nueva parcela',
    uploadPlots: 'Cargar parcelas',
    fieldCode: 'Código de campo',
    enterFieldCode: 'Ingrese el código de campo',
    plantingYear: 'Año de plantación',
    ranch: 'Rancho',
    ranchName: 'Nombre del rancho',
    trialType: 'Tipo de prueba',
    advancementGroup: 'Grupo de avance',
    enterPurpose: 'Ingrese el propósito',
    costCenter: 'Centro de costos',
    plantingSeason: 'Temporada de siembra',
    organic: 'Orgánico',
    substrate: 'Sustrato',
    factors: 'Factores',
    selectAll: 'Seleccionar todo',
    welcome: '¡Bienvenido!',
    listFootPrint: 'Huella de lista',
    addfootprint: 'Agregar huella',
    itemFootprint: 'Huella del artículo',
    itemFootprintUpper: 'HUELLA DEL ARTÍCULO',
    diffDataInOnePlace: 'Datos de diferentes entidades en un solo lugar',
    masterdataUpper: 'DATOS MAESTROS',
    metadataUpper: 'METADATOS',
    metadataTileDescrip: 'Todos los atributos y datos relacionados con entidades',
    clearAll: 'Deseleccionar todo',
    reverseAll: 'Deshaser todo',
    cannotAddNew: 'No se puede agregar un registro nuevo',
    noRecords: 'No hay registros que mostrar',
    releases: 'lanzamientos',
    logout: 'Cerrar sesión',
    update: 'Actualizar',
    plotUpdate: 'Actualización de parcelas',
    selectStatus: 'Seleccionar nuevo estado',
    translations: 'Traducciones',
    addNew: 'Agregar Nuevo',
    removeIllegalChars: 'Elimine Los Caracteres Ilegales',
    useLargerValue: 'Utilice un Valor Mayor',
    useSmallerValue: 'Utilice un Valor Menor',
    requiredField: 'Campo Obligatorio Vacío',
    correctInput: 'Por Favor, Corrija la Entrada',
    addValidEmail: 'Agregue un Correo Electrónico Válido',
    addValidUserId: 'Agregue una Identificación de Usuario Válida',
    requiredLengthTooSmall: 'Longitud Requerida Demasiado Pequeño',
    requiredLengthTooLarge: 'Longitud Requerida Demasiado Grande',
    addEditText: 'Agregar nuevo ',
    changeTypeConfirmTitle: '¿Estás Seguro de que Quieres Cambiar de',
    addNewTypeConfirmTitle: '¿Está seguro de que Desea Agregar un Nuevo',
    deleteTypeConfirmTitle: '¿Estás Seguro de que Quieres Eliminar',
    addTypeConfirmTitle: '¿Estás Seguro de que Deseas Agregar',
    editDialogText1: '¿Está Seguro de que Desea Cambiar',
    editDialogText2: 'a',
    addNewTypeDialog1: '¿Está Seguro de que Desea Agregar un Nuevo Tipo',
    addNewTypeDialog2: ''
};

export default spanish;
