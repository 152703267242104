import { Middleware } from 'one-ring';

const fpLineDataMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('ItemId', 'ItemId'),
    Middleware.CreateMapping('FootprintCode', 'FootprintCode'),
    Middleware.CreateMapping('UnitOfMeasure', 'UnitOfMeasure'),
    Middleware.CreateMapping('Length', 'Length'),
    Middleware.CreateMapping('Width', 'Width'),
    Middleware.CreateMapping('Height', 'Height'),
    Middleware.CreateMapping('GrossWeight', 'GrossWeight'),
    Middleware.CreateMapping('NetWeight', 'NetWeight'),
    Middleware.CreateMapping('UnitQuantity', 'UnitQuantity'),
    Middleware.CreateMapping('Threshold', 'Threshold'),
    Middleware.CreateMapping('UnitOfMeasureLevel', 'UnitOfMeasureLevel'),
    Middleware.CreateMapping('IsPallet', 'IsPallet'),
    Middleware.CreateMapping('IsCase', 'IsCase'),
    Middleware.CreateMapping('IsPackageUOM', 'IsPackageUOM'),
    Middleware.CreateMapping('IsStockUOM', 'IsStockUOM'),
    Middleware.CreateMapping('IsReceiveUOM', 'IsReceiveUOM'),
    Middleware.CreateMapping('IsCartonized', 'IsCartonized'),
    Middleware.CreateMapping('IsCartonDist', 'IsCartonDist'),
    Middleware.CreateMapping('IsBulkPick', 'IsBulkPick'),
    Middleware.CreateMapping('IsLayer', 'IsLayer'),
    Middleware.CreateMapping('ItemFootprintId', 'ItemFootprintId'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('FPLineData', 'GET')) {
        Middleware.RegisterMapping('FPLineData', 'GET', fpLineDataMappings);
        Middleware.RegisterMapping('FPLineData', 'POST', fpLineDataMappings);
    }
}

const FPLineData = {
    ConfigureMiddleware
};

export default FPLineData;
