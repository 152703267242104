export const COMPONENT_MODES = {
    add: 'add',
    update: 'update',
    view: 'view',
    create: 'create'
};

export const selectionOverallStatusColorMapLight = [
    {
        value: 'Active',
        color: '#095409'
    },
    {
        value: 'InActive',
        color: '#f03e3e'
    },
    {
        value: 'Germplasm',
        color: '#f03e3e'
    },
    {
        value: 'Open',
        color: '#e7f56c'
    },
    {
        value: 'Storage',
        color: '#fcba03'
    },
    {
        value: 'Public',
        color: '#46bdb9'
    },
    {
        value: 'Parent-Only',
        color: '#54379e'
    },
    {
        value: 'Parent',
        color: '#5a2b94'
    },
    {
        value: 'Variety',
        color: '#bd6800'
    },
    {
        value: 'Discard',
        color: '#bd0006'
    },
    {
        value: 'PNW1',
        color: '#0081bd'
    }
];

export const selectionOverallStatusColorMapDark = [
    {
        value: 'Active',
        color: '#095409'
    },
    {
        value: 'InActive',
        color: '#f03e3e'
    },
    {
        value: 'Germplasm',
        color: '#f03e3e'
    },
    {
        value: 'Open',
        color: '#e7f56c'
    },
    {
        value: 'Storage',
        color: '#fcba03'
    },
    {
        value: 'Public',
        color: '#46bdb9'
    },
    {
        value: 'Parent-Only',
        color: '#54379e'
    },
    {
        value: 'Parent',
        color: '#5a2b94'
    },
    {
        value: 'Variety',
        color: '#bd6800'
    },
    {
        value: 'Discard',
        color: '#bd0006'
    },
    {
        value: 'PNW1',
        color: '#0081bd'
    }
];

export const berryMap = { 1: 'BLACK', 2: 'BLUE', 3: 'RASP', 4: 'STRAW' };

export const toastType = {
    success: 'success',
    error: 'error'
};

export const middlewareNoLoaderConfig = {
    showLoadingScreen: () => {
        // body
    },
    hideLoadingScreen: () => {
        // body
    }
};

export const middlewareNoMappingConfig = {
    overrideRequestMapping: true,
    overrideResponseMapping: true
};

export const middlewareNoMappingAndLoaderConfig = {
    ...middlewareNoLoaderConfig,
    ...middlewareNoMappingConfig
};

/**
 *
 * @param {Boolean} noLoader
 * @param {Boolean} noMapping
 * @param {String} loadingMessage
 */
export const middlewareConfig = (noLoader, noMapping, loadingMessage) => {
    let config = {};
    if (noLoader) {
        config = { ...config, ...middlewareNoLoaderConfig };
    }

    if (noMapping) {
        config = { ...config, ...middlewareNoMappingConfig };
    }

    if (loadingMessage) {
        config = { ...config, loadingMessage };
    }

    return config;
};

export const boolColumns = [
    'Active',
    'IsObservable',
    'Proprietary',
    'IsRequired',
    'Substrate',
    'Organic',
    'IsOrganic',
    'IsApproved',
    'IsDSAInvestment',
    'IsActive'
];

export const statusColumns = ['ActiveStatus', 'OverallStatus'];

export const dateColumns = [
    'PlantingDate',
    'DropDate',
    'ActiveStartDate',
    'ActiveEndDate',
    'CreatedDatetime',
    'CreatedDateTime',
    'ModifiedDateTime',
    'LastChangedDateTime',
    'TransitionDate',
    'ApprovalDate'
];

export const userNameFields = ['CreatedBy', 'ModifiedBy'];

export const dataTypeMap={
    text:'Text',
    bool:'Bool',
    int:'Int',
    decimal:'Decimal',
    lookup:'Lookup',
    attribute:'Attribute',
    entity:'Entity',
    dateTime:'Datetime'
};