import { Middleware } from 'one-ring';

const plotsListMapping = [
  Middleware.CreateArrayMapping('Results', 'Results'),
];

function ConfigureMiddleware() {
  if (!Middleware.CheckMappingExists('Plots', 'POST')) {
    Middleware.RegisterMapping('Plots', 'POST', plotsListMapping);
  }
}

const plotsList = {
  ConfigureMiddleware
};

export default plotsList;
