export const SET_WAREHOUSES_RECORDS = 'SET_WAREHOUSES_RECORDS';
export const SET_METADATA = 'SET_METADATA';
export const CLEAR_ENTITY_DATA = 'CLEAR_ENTITY_DATA';
export const SET_ENTITY_FILTER = 'SET_ENTITY_FILTER';

export const setWarehousesRecords = (records, count) => ({
    type: SET_WAREHOUSES_RECORDS,
    payload: { records, count }
});

export const setMetadata = metadata => ({
    type: SET_METADATA,
    payload: metadata
});


export const clearEntityData = () => ({
    type: CLEAR_ENTITY_DATA,
    payload: null
});

export const setEntityFilter = (filters) => ({
    type: SET_ENTITY_FILTER,
    payload: filters
});