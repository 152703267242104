import { SET_FP_VALUE, CLEAR_FP_VALUE, SET_FP_LINE_VALUES, SET_FP_LINE_BOOLEANS, CLEAR_FP_LINE_VALUE, ADD_NEW_FP_LINE, CLEAR_POSTED_FP, SET_UOM_LVL_VALUES, DELETE_FP_LINE_DETAIL } from '../../../actions/actions';

const lineItemsObj = {
    ItemId: "",
    ItemCode: "",
    FootprintCode: "",
    UnitOfMeasure: "",
    Length: "",
    Width: "",
    Height: "",
    GrossWeight: "",
    NetWeight: "",
    UnitQuantity: "",
    Threshold: "100.00",
    UnitOfMeasureLevel: "",
};

const initializeBooleans = {
    Radio: {
        IsPallet: false,
        IsCase: false,
        IsLayer: false,
        IsPackageUOM: false,
    },
    Others: {
        IsStockUOM: false,
        IsReceiveUOM: false,
        IsCartonized: false,
        IsCartonDist: false,
        IsBulkPick: false

    }
};

const initialState = {
    headerDetails: {
        ItemType: { value: "" },
        ItemCode: { value: "" },
        ItemId: { value: "" },
        FootprintCode: { value: "" },
        CasePerLevel: { value: "" },
        Length: { value: "" },
        Width: { value: "" },
        Height: { value: "" },
        PalletStackHeight: { value: "" },
        IsDefault: { value: false }
    },
    lineDetails: {},
    lineDetailsIndex: 0,
    selectedFPHeader: null,
};

const footprintReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UOM_LVL_VALUES:
            var returnObj = { ...state.lineDetails };
            action.payload.values.forEach((value, idx) => {
                returnObj[idx].UnitOfMeasureLevel = String(value);
            });
            return Object.assign({}, state, {
                lineDetails: returnObj

            });
        case SET_FP_VALUE:
            return Object.assign({}, state, {
                headerDetails: {
                    ...state.headerDetails,
                    ...action.payload.value
                }
            });
        case CLEAR_FP_VALUE:
            return Object.assign({}, state, {
                ...initialState
            });
        case SET_FP_LINE_VALUES:
            var changingValue = {};
            var valueArray = Object.entries(action.payload.valueArray)[0];
            if (Object.keys(action.payload.valueArray).length === 0) {
                changingValue = {};
            }
            else if (valueArray[0] === 'UOMType') {
                changingValue = { ...initializeBooleans.Radio };
                changingValue[valueArray[1]] = true;
            } else {
                changingValue = { ...action.payload.valueArray };
            }
            return Object.assign({}, state, {
                lineDetails: {
                    ...state.lineDetails,
                    [action.payload.index]: {
                        ...lineItemsObj,
                        ...initializeBooleans.Radio,
                        ...initializeBooleans.Others,
                        ...state.lineDetails[action.payload.index],
                        ...changingValue
                    }
                }

            });
        case SET_FP_LINE_BOOLEANS:
            const name = Object.keys(action.payload.newBool)[0];

            if ((name === "IsPallet") || (name === "IsCase") || (name === "IsLayer") || (name === "IsPackageUOM")) {
                return Object.assign({}, state, {
                    lineDetails: {
                        ...state.lineDetails,
                        [action.payload.index]: {
                            ...lineItemsObj,
                            ...initializeBooleans.Others,
                            ...state.lineDetails[action.payload.index],
                            ...initializeBooleans.Radio,
                            ...action.payload.newBool
                        }
                    }

                });
            }
            else {
                return Object.assign({}, state, {
                    lineDetails: {
                        ...state.lineDetails,
                        [action.payload.index]: {
                            ...lineItemsObj,
                            ...initializeBooleans.Others,
                            ...state.lineDetails[action.payload.index],
                            ...action.payload.newBool
                        }
                    }

                });
            }


        case CLEAR_FP_LINE_VALUE:
            var FPObj = {};
            if (action.payload.index === 0) {
                FPObj = {
                    ItemId: state.headerDetails.ItemCode.value,
                    FootprintCode: state.headerDetails.FootprintCode.value
                };
            };
            return Object.assign({}, state, {
                lineDetails: {
                    ...state.lineDetails,
                    [action.payload.index]: {
                        ...lineItemsObj,
                        ...initializeBooleans.Radio,
                        ...initializeBooleans.Others,
                        ...FPObj
                    }
                }

            });
        case ADD_NEW_FP_LINE:
            return Object.assign({}, state, {
                lineDetailsIndex: (state.lineDetailsIndex + 1),
                lineDetails: {
                    ...state.lineDetails,
                    [state.lineDetailsIndex + 1]: {
                        ...lineItemsObj,
                        ...initializeBooleans.Radio,
                        ...initializeBooleans.Others,
                        ItemId: state.lineDetails[state.lineDetailsIndex].ItemId,
                        FootprintCode: state.lineDetails[state.lineDetailsIndex].FootprintCode
                    }
                }
            });
        case DELETE_FP_LINE_DETAIL:
            var newLineDetailsIdx = state.lineDetailsIndex - 1,
                newLineDetails = {},
                FPCode = { ItemCode: state.lineDetails[0].ItemCode },
                tempIdx = 0;
            Object.entries(state.lineDetails).forEach(line => {
                if (Number(line[0]) !== action.payload.index) {
                    if (tempIdx === 0) {
                        newLineDetails[String(tempIdx)] = { ...line[1], ...FPCode };
                    } else {
                        newLineDetails[String(tempIdx)] = { ...line[1] };
                    }
                    tempIdx++;
                }
            });
            return Object.assign({}, state, {
                lineDetailsIndex: newLineDetailsIdx,
                lineDetails: newLineDetails
            });
        case CLEAR_POSTED_FP:
            return Object.assign({}, state, {
                ...initialState
            });
        default:
            return state;
    }
};

export default footprintReducer;
