import {
    SET_WAREHOUSES_RECORDS,
    SET_METADATA,
    CLEAR_ENTITY_DATA,
    SET_ENTITY_FILTER
} from '../actions/warehousesActions';

const initialState = {
    metaData: null,
    records: [],
    filters: [],
    totalRecords: 0
};

const WarehousesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WAREHOUSES_RECORDS:
            return {...state, records: action.payload.records, totalRecords: action.payload.count};
        case SET_METADATA:
            return {...state, metaData: action.payload};
        case CLEAR_ENTITY_DATA:
            return {...state, records: [], totalRecords: 0};
        case SET_ENTITY_FILTER:
            return {...state, filters: action.payload};
        default:
            return state;
    }
};

export default WarehousesReducer;