import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import Translate from '@material-ui/icons/Translate';
import { LoginCallback, Security } from '@okta/okta-react';
import {
    DrcBackdrop,
    DrcEnvironmentMarker,
    DrcImage,
    DrcLoading,
    DrcMain,
    DrcMediaQueries,
    DrcPageLogin,
    DrcPageNotFound,
    DrcSecureGroupRoute,
    DrcSecureHeader,
    DrcThemeProvider,
    DrcThemeUtilities,
    DrcVersionInfo,
    Helmet,
    DrcDialog,
    DrcButton
} from 'driscolls-react-components';
import { DuThemeUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createHashHistory } from 'history';
import { hideLoadingScreenAction, setPageTitleAction, showLoadingScreenAction, showToast, serviceWorkerUpdate } from './actions/actions';
import { setInitializeRedirectUrl } from './actions/MasterActions';
import { ReactComponent as AttributesMenuItem } from './assets/AttributesMenuItem.svg';
import { ReactComponent as EntityMenuItem } from './assets/EntityMenuItem.svg';
import { ReactComponent as FootprintList } from './assets/FootprintList.svg';
import { ReactComponent as RBA } from './assets/RBA.svg';
import { ReactComponent as WH } from './assets/Warehouses.svg';
import { ReactComponent as ReleasesIcon } from './assets/Releases.svg';
import ErrorDialog from './component/dialogs/ErrorDialog';
import ErrorBoundary from './component/ErrorBoundary';
import HeaderButtons from './component/HeaderButtons';
import ManageTranslations from './component/ManageTranslations';
import TranslateText from './component/Translate';
import MasterDataUtilities from './data/MasterDataUtilities';
import InitializeApplication from './pages/InitializeApplication';
import LandingPage from './pages/LandingPage';
import LogOut from './pages/LogOut';
import LogoPng from './images/Logo_Small_Transparent.png';
import LogoWebP from './images/Logo_Small_Transparent.webp';

const FootPrintHome = lazy(() => import('./apps/ItemFootPrint/pages/FootPrintHome'));
const MasterDataHome = lazy(() => import('./apps/MasterData/pages/MasterDataHome'));
const ApplicationIdHome = lazy(() => import('./apps/ApplicationId/pages/ApplicationIdHome'));
const CreateAttribute = lazy(() => import('./apps/MDM/pages/CreateAttribute'));
const CreateEntity = lazy(() => import('./apps/MDM/pages/CreateEntity'));
const MdmHome = lazy(() => import('./apps/MDM/pages/MdmHome'));
const Warehouses = lazy(() => import('./apps/Warehouses/pages/WarehousesHome'));
const Releases = lazy(() => import('./pages/Releases'));

const allGroups = (window.config.OKTA_APP_ADMIN || []).concat(
    window.config.OKTA_RD_ADMIN_GROUPS || [],
    window.config.OKTA_RD_READ_ONLY_GROUPS || [],
    window.config.OKTA_RD_REGULAR_GROUPS || [],
    window.config.OKTA_RD_ENTITY_ADMIN || [],
    window.config.OKTA_FP_ADMIN_GROUPS || [],
    window.config.OKTA_FP_READ_ONLY_GROUPS || [],
    window.config.OKTA_FP_REGULAR_GROUPS || [],
    window.config.OKTA_RBA_USER_GROUP || [],
    window.config.OKTA_MDM_WAREHOUSE_ADMIN || [],
    window.config.OKTA_MDM_WAREHOUSE_READ_ONLY || []
);

const rdAdmins = (window.config.OKTA_APP_ADMIN || []).concat(window.config.OKTA_RD_ADMIN_GROUPS || [], window.config.OKTA_RD_ENTITY_ADMIN || []);

const rdGroups = (window.config.OKTA_APP_ADMIN || []).concat(
    window.config.OKTA_RD_ADMIN_GROUPS || [],
    window.config.OKTA_RD_READ_ONLY_GROUPS || [],
    window.config.OKTA_RD_REGULAR_GROUPS || [],
    window.config.OKTA_RD_ENTITY_ADMIN || []
);

const rbaGroup = (window.config.OKTA_APP_ADMIN || []).concat(window.config.OKTA_RBA_USER_GROUP || []);

const fpGroups = (window.config.OKTA_APP_ADMIN || []).concat(window.config.OKTA_FP_ADMIN_GROUPS || [], window.config.OKTA_FP_REGULAR_GROUPS || []);

const warehousesGroup = (window.config.OKTA_MDM_WAREHOUSE_ADMIN || []).concat(window.config.OKTA_MDM_WAREHOUSE_READ_ONLY || []);

const allLinks = [
    {
        title: <TranslateText>{'Metadata'}</TranslateText>,
        icon: <EntityMenuItem style={{ width: '18px', height: '18px' }} />,
        url: '/DataManagement/',
        requiresGroups: rdAdmins
    },
    {
        title: <TranslateText>{'Masterdata'}</TranslateText>,
        url: '/MasterData/',
        icon: <AttributesMenuItem style={{ width: '18px', height: '18px' }} />,
        requiresGroups: rdGroups
    },
    {
        title: <TranslateText>{'Footprint'}</TranslateText>,
        url: '/FootPrint/',
        icon: <FootprintList style={{ width: '18px', height: '18px' }} />,
        requiresGroups: fpGroups
    },
    {
        title: <TranslateText>{'RBA'}</TranslateText>,
        url: '/RBA/',
        icon: <RBA style={{ width: '18px', height: '18px' }} />,
        requiresGroups: rbaGroup
    },
    {
        title: <TranslateText>{'Warehouses'}</TranslateText>,
        url: '/Warehouses/',
        icon: <WH style={{ width: '18px', height: '18px' }} />,
        requiresGroups: warehousesGroup
    },
    {
        title: <TranslateText>{'Translations'}</TranslateText>,
        url: '/Translations/',
        icon: <Translate />
    },
    {
        title: <TranslateText>{'Releases'}</TranslateText>,
        url: '/Releases/',
        icon: <ReleasesIcon style={{ width: '18px', height: '18px' }} />
    },
    { title: <TranslateText>{'LogOut'}</TranslateText>, url: '/LogOut/', icon: <DirectionsRun /> }
];

const SITE_NAME = 'Master Data Management';

var styles = (theme) => ({
    '@global': {
        /* width */
        '::-webkit-scrollbar': {
            width: '10px',
            height: '10px',
            cursor: 'pointer'
        },
        /* Track */
        '::-webkit-scrollbar-track': {
            background: 'none',
            borderRadius: '2px',
            cursor: 'pointer'
        },
        /* Handle */
        '::-webkit-scrollbar-thumb': {
            backgroundColor: '#92ad8a',
            borderRadius: '2px',
            cursor: 'pointer'
        }
    },
    grid: {
        '& .gridCount': {
            margin: '-5px 10px 0 0'
        }
    },
    logo: {
        height: theme.spacing(5),
        marginRight: theme.spacing(3),
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
            filter: 'none'
        }
    },
    header: {
        '& .toolbar': {
            minHeight: theme.spacing(8),
            height: theme.spacing(8),
            maxHeight: theme.spacing(8),
            backgroundColor: '#FFF',
            textShadow: `none`
        },
        '& .title': {
            textShadow: `none`,
            fontSize: 22
        },
        '& .appBar': {
            boxShadow: `none`,
            fontSize: theme.spacing(2.5)
        },
        '& .logOut': {
            right: 0,
            position: `absolute`,
            top: theme.spacing(1.5),
            paddingRight: theme.spacing(6),
            '& a:hover': {
                backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 80)
            }
        },
        '& .logOutLink': {
            color: DuThemeUtilities.DefaultColors.primary.green,
            border: 'none'
        },
        '& .logOutTitle': {
            paddingLeft: theme.spacing(1),
            fontSize: theme.spacing(2)
        },
        '& .menuButton': {
            // border: `1px solid ${DuThemeUtilities.DefaultColors.primary.green}`
            border: `none`
        },
        '@media (prefers-color-scheme: dark)': {
            '& .toolbar': {
                backgroundColor: '#000'
            },
            '& .title': {
                color: DuThemeUtilities.DefaultColors.primary.green
            },
            '& .menuButton': {
                // border: `1px solid ${DuThemeUtilities.DefaultColors.primary.green}`
                border: `none`
            },
            '& .menuButton svg': {
                color: DuThemeUtilities.DefaultColors.primary.green
            }
        }
    },
    errorToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    successToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    STRAW: {
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.red), 80),
        '& .p-datatable-scrollable-header': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.red), 80)
        }
    },
    BLACK: {
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.purple), 80),
        '& .p-datatable-scrollable-header': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.purple), 80)
        }
    },
    RASP: {
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.pink), 80),
        '& .p-datatable-scrollable-header': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.pink), 80)
        }
    },
    BLUE: {
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.blue), 80),
        '& .p-datatable-scrollable-header': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.blue), 80)
        }
    },
    WHITE: {
        backgroundColor: '#f0f0f0',
        '& .p-datatable-scrollable-header': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.blue), 80)
        },
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#333'
        }
    },
    appmain: {
        padding: '4rem 0rem 2rem 0rem',
        minHeight: '100vh'
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            theme: DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green),
            showNotification: false
        };
        this.history = createHashHistory();
        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : '/Apps/');
        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);

        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setErrorsAction);
    }

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.showToast('');
    };

    updateServiceWorker = () => {
        const registrationWaiting = this.props.serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', (e) => {
                if (e.target.state === 'activated') {
                    this.props.serviceWorkerUpdate();
                    window.location.href = '/logOut';
                }
            });
        }
    };

    closeNewVersionDialog = () => {
        this.props.serviceWorkerUpdate();
    };

    render() {
        return (
            <div
                className={`${this.props.classes.appmain} ${
                    window.location.pathname.indexOf('MasterData') > -1 ? this.props.classes[this.props.berry] : this.props.classes.WHITE
                }`}
            >
                <DrcThemeProvider fontFamily="Rubik" theme={this.state.theme}>
                    <DrcEnvironmentMarker />
                    <Helmet>
                        <title>{this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}</title>
                    </Helmet>
                    <Router history={this.history}>
                        <Security
                            issuer={window.config.OKTA_ISSUER}
                            client_id={window.config.OKTA_CLIENT_ID}
                            redirect_uri={window.location.origin + '/implicit/callback'}
                            onAuthRequired={() => {
                                this.history.push('/');
                            }}
                            auto_renew={true}
                            scopes={['openid', 'email', 'MulesoftAPIAccess']}
                        >
                            <ErrorBoundary path={window.location.pathname}>
                                <DrcSecureHeader
                                    title={this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}
                                    allLinks={window.location.pathname === '/InitializeApplication/' ? [] : allLinks}
                                    fullWidth={true}
                                    logo={<DrcImage src={LogoPng} webp={LogoWebP} className={this.props.classes.logo} alt="Driscoll's Logo" />}
                                    className={this.props.classes.header}
                                >
                                    <HeaderButtons />
                                </DrcSecureHeader>
                                <Suspense
                                    fallback={
                                        <DrcMain transparent>
                                            <DrcLoading />
                                        </DrcMain>
                                    }
                                >
                                    <Switch>
                                        <Route
                                            path="/"
                                            exact
                                            render={(props) => <DrcPageLogin {...props} setPageTitle={this.props.setPageTitle} />}
                                        />
                                        <DrcSecureGroupRoute
                                            path="/InitializeApplication/"
                                            exact
                                            component={InitializeApplication}
                                            groupsAllowed={allGroups}
                                        />

                                        <DrcSecureGroupRoute path="/Apps/" exact component={LandingPage} groupsAllowed={allGroups} />
                                        <DrcSecureGroupRoute
                                            exact
                                            path="/DataManagement/Entity/:Mode"
                                            component={CreateEntity}
                                            groupsAllowed={rdAdmins}
                                        />
                                        <DrcSecureGroupRoute
                                            exact
                                            path="/DataManagement/Attribute/:Mode"
                                            component={CreateAttribute}
                                            groupsAllowed={rdAdmins}
                                        />
                                        <DrcSecureGroupRoute path="/DataManagement/" component={MdmHome} groupsAllowed={rdAdmins} />
                                        <DrcSecureGroupRoute path="/FootPrint/" component={FootPrintHome} groupsAllowed={fpGroups} />
                                        <DrcSecureGroupRoute path="/MasterData/" component={MasterDataHome} groupsAllowed={rdGroups} />
                                        <DrcSecureGroupRoute path="/Warehouses/" component={Warehouses} groupsAllowed={warehousesGroup} />
                                        <DrcSecureGroupRoute path="/RBA/" component={ApplicationIdHome} groupsAllowed={rbaGroup} />
                                        <DrcSecureGroupRoute path="/Translations/" exact component={ManageTranslations} groupsAllowed={allGroups} />
                                        <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={allGroups} />
                                        <Route path="/implicit/callback" component={LoginCallback} />
                                        <Route path="/LogOut/" exact component={LogOut} />
                                        <Route component={DrcPageNotFound} />
                                    </Switch>
                                </Suspense>
                            </ErrorBoundary>
                        </Security>
                        <DrcVersionInfo allowClick={this.props.isAdmin} />
                        <DrcBackdrop isLoading show={this.props.showLoadingScreen} loadingMessage={this.props.loadingMessage} />
                        <ErrorDialog isError errorDialog={this.props.errorDialog} open={this.props.errorDialog.show}></ErrorDialog>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            className={`${this.props.toastType === 'success' && this.props.classes.successToast} ${
                                this.props.toastType === 'error' && this.props.classes.errorToast
                            }`}
                            open={this.props.toastMessage !== ''}
                            autoHideDuration={6000}
                            onClose={this.handleCloseNotification}
                            message={this.props.toastMessage}
                        ></Snackbar>
                        <DrcDialog
                            title={'New version available'}
                            open={this.props.serviceWorkerUpdated}
                            buttons={
                                <>
                                    <DrcButton onClick={this.closeNewVersionDialog}>Close</DrcButton>
                                    <DrcButton isPrimary onClick={this.updateServiceWorker}>
                                        Ok
                                    </DrcButton>
                                </>
                            }
                        >
                            <div>Refresh to update your app?'</div>
                        </DrcDialog>
                    </Router>
                </DrcThemeProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        toastType: state.rootReducer.toastType,
        toastMessage: state.rootReducer.toastMessage,
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingMessage: state.rootReducer.loadingMessage,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle,
        isAdmin: state.userReducer.isAdmin,
        berry: state.masterReducer.berry,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showLoadingScreenAction: (title) => dispatch(showLoadingScreenAction(title)),
    showToast: (message) => dispatch(showToast(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    setInitializeRedirectUrl: (url) => dispatch(setInitializeRedirectUrl(url)),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));
