export const SET_ENTITY_TO_UPDATE = 'SET_ENTITY_TO_UPDATE';
export const setUpdateEntity = (entity) => ({
    type: SET_ENTITY_TO_UPDATE,
    payload: entity
});

export const SET_ENTITY_CONDITIONAL_LOOKUPS = 'SET_ENTITY_CONDITIONAL_LOOKUPS';
export const setEntityConditionalLookups = (lookups) => ({
    type: SET_ENTITY_CONDITIONAL_LOOKUPS,
    payload: lookups
});
