import { Middleware } from 'one-ring';

const selectionsMapping = [
    Middleware.CreateMapping('SelectionId', 'SelectionId'),
    Middleware.CreateMapping('IsRequired', 'IsRequired'),
    Middleware.CreateMapping('Selection', 'Selection'),
    Middleware.CreateMapping('BerryType', 'BerryType'),
    Middleware.CreateMapping('VarietyName', 'VarietyName'),
    Middleware.CreateMapping('Active', 'Active'),
    Middleware.CreateMapping('ActiveStartDate', 'ActiveStartDate'),
    Middleware.CreateMapping('ActiveEndDate', 'ActiveEndDate'),
    Middleware.CreateMapping('SrcBreedingProgram', 'SrcBreedingProgram'),
    Middleware.CreateMapping('ParentYear', 'ParentYear'),
    Middleware.CreateMapping('Proprietary', 'Proprietary'),
    Middleware.CreateMapping('SelectedDate', 'SelectedDate'),
    Middleware.CreateMapping('FloweringType', 'FloweringType'),
    Middleware.CreateMapping('Series', 'Series'),
    Middleware.CreateMapping('Species', 'Species'),
    Middleware.CreateMapping('PlantingYear', 'PlantingYear'),
    Middleware.CreateMapping('OwnershipType', 'OwnershipType'),
    Middleware.CreateMapping('SeedParent', 'SeedParent'),
    Middleware.CreateMapping('ActiveStatus', 'ActiveStatus'),
    Middleware.CreateMapping('PollenParent', 'PollenParent'),
    Middleware.CreateMapping('SeedingYear', 'SeedingYear'),
    Middleware.CreateMapping('SeedlingRow', 'SeedlingRow'),
    Middleware.CreateMapping('SeedlingPlant', 'SeedlingPlant'),
    Middleware.CreateMapping('Comments', 'Comments'),
    Middleware.CreateMapping('OverallStatus', 'OverallStatus'),
    Middleware.CreateMapping('OverallStatusModifiedBy', 'OverallStatusModifiedBy'),
    Middleware.CreateMapping('Advanced', 'Advanced'),
    Middleware.CreateMapping('SXA_BotanicalGroup', 'SXA_BotanicalGroup'),
    Middleware.CreateMapping('SXA_Spine', 'SXA_Spine'),
    Middleware.CreateMapping('SXA_BerryColor', 'SXA_BerryColor'),
    Middleware.CreateMapping('SXA_SpineColor', 'SXA_SpineColor'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('TotalRowCount', 'TotalRowCount'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

const filterSelectionsMapping = [
    Middleware.CreateMapping('SelectionId', 'SelectionId'),
    Middleware.CreateMapping('IsRequired', 'IsRequired'),
    Middleware.CreateMapping('Selection', 'Selection'),
    Middleware.CreateMapping('BerryType', 'BerryType'),
    Middleware.CreateMapping('VarietyName', 'VarietyName'),
    Middleware.CreateMapping('Active', 'Active'),
    Middleware.CreateMapping('ActiveStartDate', 'ActiveStartDate'),
    Middleware.CreateMapping('ActiveEndDate', 'ActiveEndDate'),
    Middleware.CreateMapping('SrcBreedingProgram', 'SrcBreedingProgram'),
    Middleware.CreateMapping('ParentYear', 'ParentYear'),
    Middleware.CreateMapping('Proprietary', 'Proprietary'),
    Middleware.CreateMapping('SelectedDate', 'SelectedDate'),
    Middleware.CreateMapping('FloweringType', 'FloweringType'),
    Middleware.CreateMapping('Series', 'Series'),
    Middleware.CreateMapping('Species', 'Species'),
    Middleware.CreateMapping('PlantingYear', 'PlantingYear'),
    Middleware.CreateMapping('OwnershipType', 'OwnershipType'),
    Middleware.CreateMapping('SeedParent', 'SeedParent'),
    Middleware.CreateMapping('ActiveStatus', 'ActiveStatus'),
    Middleware.CreateMapping('PollenParent', 'PollenParent'),
    Middleware.CreateMapping('SeedingYear', 'SeedingYear'),
    Middleware.CreateMapping('SeedlingRow', 'SeedlingRow'),
    Middleware.CreateMapping('SeedlingPlant', 'SeedlingPlant'),
    Middleware.CreateMapping('Comments', 'Comments'),
    Middleware.CreateMapping('OverallStatus', 'OverallStatus'),
    Middleware.CreateMapping('OverallStatusModifiedBy', 'OverallStatusModifiedBy'),
    Middleware.CreateMapping('Advanced', 'Advanced'),
    Middleware.CreateMapping('SXA_BotanicalGroup', 'SXA_BotanicalGroup'),
    Middleware.CreateMapping('SXA_Spine', 'SXA_Spine'),
    Middleware.CreateMapping('SXA_BerryColor', 'SXA_BerryColor'),
    Middleware.CreateMapping('SXA_SpineColor', 'SXA_SpineColor'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('TotalRowCount', 'TotalRowCount'),
    Middleware.CreateArrayMapping('Records', 'Records')
];
function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Selections', 'GET')) {
        Middleware.RegisterMapping('Selections', 'GET', selectionsMapping);
        Middleware.RegisterMapping('Selections', 'POST', filterSelectionsMapping);
    }
}

const selection = {
    ConfigureMiddleware
};

export default selection;
