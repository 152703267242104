import { Middleware } from 'one-ring';

const plotPlantingSeasonListMapping = [
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('PlotPlantingSeasonData', 'GET')) {
        Middleware.RegisterMapping('PlotPlantingSeasonData', 'GET', plotPlantingSeasonListMapping);
    }
}

const plotPlantingSeasonMapping = {
    ConfigureMiddleware
};

export default plotPlantingSeasonMapping;
