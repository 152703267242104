import { ADD_TO_PENDING_TRANSLATIONS, CLEAR_PENDING_TRANSLATIONS, SET_TRANSLATION_RULE, SET_USER_LANGUAGE } from '../actions/translationActions';

const initialState = {
    pending: {},
    rules: {},
    preferredLanguageCode: localStorage.getItem('preferredLanguageCode'),
    preferredLanguage: localStorage.getItem('preferredLanguage')
};

const TranslationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSLATION_RULE:
            return {
                ...state,
                rules: action.payload
            };
        case ADD_TO_PENDING_TRANSLATIONS:
            return {
                ...state,
                pending: {
                    ...state.pending,
                    [action.payload]: ''
                }
            };
        case CLEAR_PENDING_TRANSLATIONS:
            return { ...state, pending: {} };
        case SET_USER_LANGUAGE:
            let language = action.payload.lan;
            let preferredLanguageCode = 'en';
            let preferredLanguage = 'English';
            if (language && language.toLowerCase() !== 'Default'.toLowerCase() && language.toLowerCase() !== 'English'.toLowerCase()) {
                preferredLanguageCode = 'es';
                preferredLanguage = 'Spanish';
            }

            // set on local storage to save the data on browser refresh
            localStorage.setItem('preferredLanguage', preferredLanguage);
            localStorage.setItem('preferredLanguageCode', preferredLanguageCode);

            return { ...state, preferredLanguageCode, preferredLanguage };
        default:
            return state;
    }
};

export default TranslationReducer;
