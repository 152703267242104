import {
    SET_ENTITY_DATA,
    SET_ENTITY_METADATA,
    SET_ENTITY_FILTERS,
    SET_FILTERED_ENTITY_DATA,
    SET_ENTITY_FILTER,
    CLEAR_ENTITY_DATA
} from '../actions/selectionActions';

const initialState = {
    metaData: null,
    records: [],
    filters: [],
    totalRecords: 0
};

const SelectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ENTITY_DATA:
            let records = [];
            records = action.payload.records;

            return Object.assign({}, state, {
                records,
                totalRecords: action.payload.count
            });
        case CLEAR_ENTITY_DATA:
            return Object.assign({}, state, {
                records: [],
                totalRecords: 0
            });
        case SET_FILTERED_ENTITY_DATA:
            return Object.assign({}, state, {
                records: action.payload.records,
                totalRecords: action.payload.count
            });
        case SET_ENTITY_METADATA:
            let isColumnsEditable = action.payload.Fields.find((item) => item.IsUpdatable === true) !== undefined;
            /*
            We are getting 2 records for FloweringType in metadata. So we have to filter-out one based on the selected berry type.
            One is of type Attribute for Strawberry
            Another is of type Text for all other berries
            */
            if (action.payload.EntityName === 'Selection') {
                let filteredFloweringType = [];
                let filteredMetadata = [];
                action.payload.Fields.map((data) => {
                    filteredMetadata.push(data);
                    return null;
                });
                let fields = filteredMetadata
                    .map((item) => {
                        return !item.Order ? { ...item, Order: 100 } : item;
                    })
                    .sort((a, b) => (a.Order && b.Order ? parseInt(a.Order) - parseInt(b.Order) : 0));
                return Object.assign({}, state, {
                    metaData: { ...action.payload, Fields: fields },
                    floweringType: filteredFloweringType,
                    areColumnsReadOnly: !isColumnsEditable
                });
            } else {
                let fields = action.payload.Fields.map((item) => {
                    return !item.Order ? { ...item, Order: 100 } : item;
                }).sort((a, b) => (a.Order && b.Order ? parseInt(a.Order) - parseInt(b.Order) : 0));
                action.payload.Fields = fields;
                return Object.assign({}, state, {
                    metaData: action.payload,
                    areColumnsReadOnly: !isColumnsEditable
                });
            }
        case SET_ENTITY_FILTERS:
            return Object.assign({}, state, {
                filters: action.payload
            });
        case SET_ENTITY_FILTER:
            return Object.assign({}, state, {
                filters: action.payload
            });
        default:
            return state;
    }
};

export default SelectionReducer;
