import React from 'react';
import { connect } from 'react-redux';
import { setPageTitleAction } from '../actions/actions';
import { setBerry } from '../actions/MasterActions';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { DrcPanel, DrcIcons } from 'driscolls-react-components';
import { withStyles } from '@material-ui/styles';
import LandingPageTile from '../component/LandingPageTile';
import { ReactComponent as AttributesMenuItem } from '../assets/AttributesMenuItem.svg';
import { ReactComponent as EntityMenuItem } from '../assets/EntityMenuItem.svg';
import { ReactComponent as FootprintList } from '../assets/FootprintList.svg';
import { ReactComponent as Warehouses } from '../assets/Warehouses.svg';
import { ReactComponent as RBA } from '../assets/RBA.svg';
import { withOktaAuth } from '@okta/okta-react';
import { DuThemeUtilities, DuAuthenticationUtilities } from 'driscolls-react-utilities';
import startCase from 'lodash/startCase';
import Translate from '../component/Translate';

const styles = (theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: '7rem'
    },
    container: {
        [theme.darkTheme]: {
            border: 'solid 1px #222',
            boxShadow: 'none'
        },
        boxShadow: '1px 1px 8px 3px #d6d6d6',
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100vw - 200px)',
        height: 'calc(100vh - 200px)',
        border: 'none',
        borderRadius: '2px',
        padding: '4rem',
        overflow: 'scroll',
        maxWidth: '1200px !important'
    },
    username: {
        fontSize: '1.8rem',
        [theme.darkTheme]: {
            color: '#aaa'
        }
    },
    tileContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        marginTop: '2rem',
        flexWrap: 'wrap'
    }
});

const pageTitle = 'Applications';

const rdAdmins = (window.config.OKTA_APP_ADMIN || []).concat(window.config.OKTA_RD_ADMIN_GROUPS || [], window.config.OKTA_RD_ENTITY_ADMIN || []);

const rdGroups = (window.config.OKTA_APP_ADMIN || []).concat(
    window.config.OKTA_RD_ADMIN_GROUPS || [],
    window.config.OKTA_RD_READ_ONLY_GROUPS || [],
    window.config.OKTA_RD_REGULAR_GROUPS || [],
    window.config.OKTA_RD_ENTITY_ADMIN || []
);

const fpGroups = (window.config.OKTA_APP_ADMIN || []).concat(window.config.OKTA_FP_ADMIN_GROUPS || [], window.config.OKTA_FP_REGULAR_GROUPS || []);
const rbaGroup = (window.config.OKTA_APP_ADMIN || []).concat(window.config.OKTA_RBA_USER_GROUP || []);

const warehousesGroup = (window.config.OKTA_MDM_WAREHOUSE_ADMIN || []).concat(window.config.OKTA_MDM_WAREHOUSE_READ_ONLY || []);

class LandingPage extends React.Component {
    state = {
        username: '',
        accessibleTiles: []
    };

    async componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }

        try {
            let token = await this.props.authService.getAccessToken();
            let userId = DuAuthenticationUtilities.GetUserId(token);
            let accessibleTiles = this.tiles
                .map((tile) => {
                    if (tile.accessGroups && tile.accessGroups.length) {
                        if (DuAuthenticationUtilities.IsInGroup(token, tile.accessGroups)) {
                            return tile;
                        }
                        return null;
                    } else {
                        return tile;
                    }
                })
                .filter((e) => e);

            this.setState({
                username: startCase(userId.split('.')[0]),
                accessibleTiles
            });
        } catch (err) {
            console.log(err);
        }
    }

    // if accessGroups prop is [] or not defined the tile will have full access
    tiles = [
        {
            icon: <EntityMenuItem style={{ width: '40px', height: '40px', fill: `${DuThemeUtilities.DefaultColors.primary.pink}` }} />,
            header: <Translate>METADATA</Translate>,
            details: <Translate>All attributes & entities related data</Translate>,
            color: `${DuThemeUtilities.DefaultColors.primary.pink}`,
            links: [
                {
                    handler: () => this.props.history.push('/DataManagement/Entities'),
                    text: <Translate>Entities</Translate>,
                    icon: ''
                },
                {
                    handler: () => this.props.history.push('/DataManagement/Attributes'),
                    text: <Translate>Attributes</Translate>,
                    icon: ''
                }
            ],
            accessGroups: rdAdmins
        },
        {
            icon: <AttributesMenuItem style={{ width: '40px', height: '40px', fill: `${DuThemeUtilities.DefaultColors.primary.blue}` }} />,
            header: <Translate>MASTERDATA</Translate>,
            details: <Translate>Different entities data at one place</Translate>,
            color: `${DuThemeUtilities.DefaultColors.primary.blue}`,
            links: [
                {
                    handler: () => {
                        this.props.setBerry('BLACK');
                        this.props.history.push('/MasterData/Selections');
                        localStorage.setItem('selectedBerry', 'BLACK');
                    },
                    text: '',
                    icon: DrcIcons.GetBerryIcon('BLACK')
                },
                {
                    handler: () => {
                        localStorage.setItem('selectedBerry', 'BLUE');
                        this.props.setBerry('BLUE');
                        this.props.history.push('/MasterData/Selections');
                    },
                    text: '',
                    icon: DrcIcons.GetBerryIcon('BLUE')
                },
                {
                    handler: () => {
                        localStorage.setItem('selectedBerry', 'RASP');
                        this.props.setBerry('RASP');
                        this.props.history.push('/MasterData/Selections');
                    },
                    text: '',
                    icon: DrcIcons.GetBerryIcon('RASP')
                },
                {
                    handler: () => {
                        localStorage.setItem('selectedBerry', 'STRAW');
                        this.props.setBerry('STRAW');
                        this.props.history.push('/MasterData/Selections');
                    },
                    text: '',
                    icon: DrcIcons.GetBerryIcon('STRAW')
                }
            ],
            accessGroups: rdGroups
        },
        {
            icon: <FootprintList style={{ width: '40px', height: '40px', fill: `${DuThemeUtilities.DefaultColors.primary.purple}` }} />,
            header: <Translate>ITEM FOOTPRINT</Translate>,
            details: <Translate>Item Footprint</Translate>,
            color: `${DuThemeUtilities.DefaultColors.primary.purple}`,
            links: [
                {
                    handler: () => this.props.history.push('/FootPrint/Add'),
                    text: <Translate>Add Footprint</Translate>,
                    icon: ''
                },
                {
                    handler: () => this.props.history.push('/FootPrint/List'),
                    text: <Translate>List Footprint</Translate>,
                    icon: ''
                }
            ],
            accessGroups: fpGroups
        },
        {
            icon: <RBA style={{ width: '40px', height: '40px', fill: `${DuThemeUtilities.DefaultColors.primary.green}` }} />,
            header: <Translate>RBA</Translate>,
            details: <Translate>Create app IDs, view block-app ID mapping</Translate>,
            color: `${DuThemeUtilities.DefaultColors.primary.green}`,
            links: [
                {
                    handler: () => this.props.history.push('/RBA/CreateAppID'),
                    text: <Translate>Create New App ID</Translate>,
                    icon: ''
                },
                {
                    handler: () => this.props.history.push('/RBA/BlockAppIDMapping'),
                    text: <Translate>Block-App ID</Translate>,
                    icon: ''
                }
            ],
            accessGroups: rbaGroup
        },
        {
            icon: <Warehouses style={{ width: '40px', height: '40px', fill: `${DuThemeUtilities.DefaultColors.primary.red}` }} />,
            header: <Translate>WAREHOUSES</Translate>,
            details: <Translate>See all warehouses</Translate>,
            color: `${DuThemeUtilities.DefaultColors.primary.red}`,
            links: [
                {
                    handler: () => this.props.history.push('/Warehouses/Warehouses'),
                    text: <Translate>Warehouses</Translate>,
                    icon: ''
                }
            ],
            accessGroups: warehousesGroup
        }
    ];

    render() {
        var { isMasterDataInitialized, classes } = this.props;

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <div className={classes.main}>
                <DrcPanel className={classes.container}>
                    <div className={classes.username}>
                        <Translate>Welcome</Translate>
                        {` ${this.state.username},`}
                    </div>
                    <div className={classes.tileContainer}>
                        {this.state.accessibleTiles.map((tile) => (
                            <LandingPageTile id={tile.header} color={tile.color} icon={tile.icon} header={tile.header} details={tile.details} links={tile.links} />
                        ))}
                    </div>
                </DrcPanel>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setBerry: (berry) => dispatch(setBerry(berry))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LandingPage)));
