import { SET_IMPORTED_FILE_DATA, SET_VALIDATION_DATA } from '../actions/importFileActions';

const initialState = {
  fileData: [],
  validationData: {},
  berryType: ''
};

const ImportFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IMPORTED_FILE_DATA:
      return Object.assign({}, state, {
        fileData: action.payload.data,
        berryType: action.payload.berry
      });
    case SET_VALIDATION_DATA:
      return Object.assign({}, state, {
        validationData: action.payload
      });
    default:
      return state;
  }
};

export default ImportFileReducer;
