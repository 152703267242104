import { withStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { withOktaAuth } from '@okta/okta-react';
import { DrcButton, DrcDialog, DrcLegend } from 'driscolls-react-components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideErrorDialogAction } from '../../actions/actions';

const styles = () => ({
    dialogWrapper: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '1200px !important' //TO override dialog maxWidth
        }
    },
    errorDialogContent: {
        maxHeight: '35vh',
        overflowY: 'auto',
        overflowWrap: 'break-word'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
});
class ErrorDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showErrorDetails: false
        };
        this.results = [];
    }

    componentDidMount() {
        // body
    }

    showErrorDetailsSection = () => {
        this.setState({ showErrorDetails: !this.state.showErrorDetails });
    };

    getErrorDetails = () => {
        try {
            let stack = this.props.errorDialog.stack ? this.props.errorDialog.stack : null;
            var details = stack && stack.data && stack.data.response ? stack.data.response.data : { Error: 'Unknown Error' };
            if (details.AdditionalErrorDetails && details.AdditionalErrorDetails.ErrorDetails) {
                this.processMule(details);
            } else {
                this.results.push({ Error: 'Unknown Error' });
            }
        } catch (error) {
            this.results.push({ Error: 'Unknown Error' });
        }
        let items = [];
        this.results.map((item, i) => {
            Object.keys(item).map((key) => {
                items.push(
                    <div className={this.props.classes.flexContainer} key={i}>
                        <h3 style={{ height: '5px' }}>{key.toUpperCase()}</h3>
                        <TextareaAutosize style={{ border: 'solid 1px #ffffff', backgroundColor: 'white' }} disabled value={item[key]} />
                    </div>
                );
                return null;
            });
            return null;
        });
        return items;
    };

    processMule = (errorData) => {
        Object.keys(errorData).map((item) => {
            if (item === 'AdditionalErrorDetails' || item === 'ErrorDetails') {
                this.processMule(errorData[item]);
            } else {
                let obj = {};
                obj[item] = errorData[item];
                this.results.push(obj);
            }
            return null;
        });
    };

    flatten = (a) => {
        return Array.isArray(a) ? [].concat.apply([], a.map(this.flatten)) : a;
    };

    render() {
        const errorDetails = this.getErrorDetails();
        return (
            <DrcDialog
                isError
                title={this.props.errorDialog.title}
                open={this.props.errorDialog.show}
                buttons={
                    <div className="row">
                        <DrcButton isPrimary onClick={this.props.hideErrorDialog}>
                            OK
                        </DrcButton>
                        {this.props.errorDialog.stack && (
                            <DrcButton isPrimary onClick={this.showErrorDetailsSection}>
                                {this.state.showErrorDetails ? 'Hide Details' : 'Show Details'}
                            </DrcButton>
                        )}
                    </div>
                }
            >
                <div>
                    {this.props.errorDialog.content}
                    {this.state.showErrorDetails ? (
                        <>
                            <DrcLegend>Details</DrcLegend>
                            <div className={this.props.classes.errorDialogContent}>{errorDetails}</div>
                        </>
                    ) : null}
                </div>
            </DrcDialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    hideErrorDialog: () => dispatch(hideErrorDialogAction())
});

export default withOktaAuth(connect(null, mapDispatchToProps)(withStyles(styles)(ErrorDialog)));
