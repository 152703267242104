import { Middleware } from 'one-ring';

const varietyMapping = [Middleware.CreateMapping('Results', 'Results')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Variety', 'GET')) {
        Middleware.RegisterMapping('Variety', 'GET', varietyMapping);
    }
}

const VarietyData = {
    ConfigureMiddleware
};

export default VarietyData;
