// break this
class APIEndPoints {
    static BASE = window.config.API_BASE_ADDRESS;
    static FP_BASE = window.config.API_FP_BASE_ADDRESS;

    static FP_HEADERS = this.FP_BASE + 'itemfootprint/header';
    static FP_LINE_DETAILS = this.FP_BASE + 'itemfootprint/line';
    static FP_ITEM_CODE = this.FP_BASE + 'itemfootprint/itemcode';

    static DATA_ENTITY_LIST = this.BASE + 'entity';
    static DATA_ATTRIBUTE_LIST = this.BASE + 'attributes';
    static ROLES =  this.BASE + 'roles';

    static FP_BY_CODE = (itemId) => {
        return `${this.FP_LINE_DETAILS}/${itemId}`;
    };

    static FP_HEADER_BY_TYPE = (type) => {
        return `${this.FP_HEADERS}/${type}`;
    };

    static ITEM_BY_TYPE = (type) => {
        return `${this.FP_ITEM_CODE}/${type}`;
    };

    static LANGUAGES = this.BASE + 'languages';
    static DATATYPE_LIST = this.BASE + 'datatype';
    static ATTRIBUTE_LIST = '';

    static TRANSLATION = this.BASE + 'translation';
    static VALIDATION_DATA_LIST = this.BASE + 'berrytype/validations';
}

export default APIEndPoints;
