import { combineReducers } from 'redux';
import modalReducer from './modalReducer';
import footprintReducer from '../apps/ItemFootPrint/reducers/footprintReducer';
import filterReducer from '../apps/ItemFootPrint/reducers/filterReducer';
import masterReducer from './MasterReducer';
import userReducer from './userReducer';
import importFileReducer from './importFileReducer';
import mdmReducer from '../apps/MDM/reducers';
import masterDataReducer from '../apps/MasterData/reducers';
import warehousesReducer from '../apps/Warehouses/reducers';
import rbaReducer from '../apps/ApplicationId/reducers';
import {
    SHOW_LOADING_SCREEN,
    HIDE_LOADING_SCREEN,
    SET_ERRORS,
    HIDE_ERROR_DIALOG,
    SET_PAGE_TITLE,
    SHOW_TOAST,
    SERVICE_WORKER_UPDATED,
    SERVICE_WORKER_UPDATE
} from '../actions/actions';
import translationReducer from './translationReducer';
import { localizeReducer } from 'react-localize-redux';

const initialState = {
    plantTypes: [],
    varietyOptions: [],
    fieldTypes: [],
    inventoryTypes: [],
    isDarkMode: localStorage.getItem('darkMode') === 'true',
    loadingMessage: 'Loading...',
    showLoadingScreen: false,
    pageTitle: '',
    errorDialog: {
        show: false,
        title: 'Unknown Error',
        content: 'Whazzzuuppp',
        stack: null
    },
    toastMessage: '',
    toastType: '',
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case '@@localize/SET_ACTIVE_LANGUAGE':
            let language = action.payload.languageCode;
            let preferredLanguageCode = 'en';
            let preferredLanguage = 'English';
            if (language && language !== 'en') {
                preferredLanguageCode = 'es';
                preferredLanguage = 'Spanish';
            }
            // set on local storage to save the data on browser refresh
            localStorage.setItem('preferredLanguage', preferredLanguage);
            localStorage.setItem('preferredLanguageCode', preferredLanguageCode);

            return { ...state, preferredLanguageCode, preferredLanguage };
        case SET_PAGE_TITLE:
            return Object.assign({}, state, {
                ...state,
                pageTitle: action.payload
            });
        case SHOW_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: true,
                loadingMessage: action.payload.title
            };
        case HIDE_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: false,
                loadingScreenMessage: ''
            };
        case SET_ERRORS:
            return {
                ...state,
                errorDialog: {
                    show: true,
                    title: action.payload.title,
                    content: action.payload.errors,
                    stack: action.payload.errorstack
                }
            };
        case HIDE_ERROR_DIALOG:
            return {
                ...state,
                errorDialog: {
                    show: false,
                    title: '',
                    content: ''
                }
            };
        case SHOW_TOAST:
            return {
                ...state,
                toastMessage: action.payload.toastMessage,
                toastType: action.payload.toastType || 'success'
            };
        case SERVICE_WORKER_UPDATE:
            return {
                ...state,
                serviceWorkerUpdated: true,
                serviceWorkerRegistration: action.payload
            };
        case SERVICE_WORKER_UPDATED:
            return {
                ...state,
                serviceWorkerUpdated: false
            };
        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    masterReducer,
    modalReducer,
    footprintReducer,
    userReducer,
    importFileReducer,
    mdmReducer,
    masterDataReducer,
    rbaReducer,
    filterReducer,
    translationReducer,
    warehousesReducer,
    localize: localizeReducer
});
