import { SET_ENTITY_TO_UPDATE, SET_ENTITY_CONDITIONAL_LOOKUPS } from '../actions/entityActions';

const initialState = {
    entityToUpdate: {},
    conditionalLookups: {}
};

const EntityReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ENTITY_TO_UPDATE:
            return Object.assign({}, state, {
                entityToUpdate: action.payload
            });
        case SET_ENTITY_CONDITIONAL_LOOKUPS:
            return Object.assign({}, state, {
                conditionalLookups: {
                    ...state.conditionalLookups,
                    ...action.payload
                }
            });
        default:
            return state;
    }
};

export default EntityReducer;
