import { combineReducers } from 'redux';
import selectionReducer from './selectionReducer';
import locationReducer from './locationReducer';
import othersReducer from './othersReducer';
import plotsReducer from './plotsReducer';
import historyReducer from './historyReducer';

export default combineReducers({
    selectionReducer,
    locationReducer,
    othersReducer,
    plotsReducer,
    historyReducer
});
