import { Middleware } from 'one-ring';

const treatmentMapping = [Middleware.CreateMapping('Results', 'Results')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('TreatmentTypes', 'GET')) {
        Middleware.RegisterMapping('TreatmentTypes', 'GET', treatmentMapping);
    }
}

const TreatmentTypeData = {
    ConfigureMiddleware
};

export default TreatmentTypeData;
