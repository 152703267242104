import { Middleware } from 'one-ring';

const createEntityMapping = [
    Middleware.CreateMapping('entityId', 'EntityId'),
    Middleware.CreateMapping('entityName', 'EntityName'),
    Middleware.CreateMapping('entityDisplayName', 'DisplayName'),
    Middleware.CreateMapping('entityDescription', 'EntityDescription'),
    Middleware.CreateMapping('entityAccessControl', 'EntityAccessControl'),
    Middleware.CreateMapping('languageName', 'LanguageName'),
    Middleware.CreateArrayMapping('fields', 'Fields'),
    Middleware.CreateMapping('fieldName', 'FieldName'),
    Middleware.CreateMapping('displayName', 'DisplayName'),
    Middleware.CreateMapping('entityDetailId', 'EntityDetailId'),
    Middleware.CreateMapping('dataType', 'DataType'),
    Middleware.CreateMapping('description', 'Description'),
    Middleware.CreateMapping('isUnique', 'IsUnique'),
    Middleware.CreateMapping('isSearchable', 'IsSearchable'),
    Middleware.CreateMapping('isEditable', 'IsEditable'),
    Middleware.CreateMapping('isConditional', 'IsConditional'),
    Middleware.CreateMapping('fieldEntityName', 'FieldEntityName'),
    Middleware.CreateMapping('entityName', 'EntityName'),
    Middleware.CreateMapping('entityFields', 'EntityFields'),
    Middleware.CreateMapping('attributeName', 'AttributeName'),
    Middleware.CreateMapping('attributeId', 'AttributeId'),
    Middleware.CreateMapping('accessControl', 'AccessControl'),
    Middleware.CreateMapping('conditionalValues', 'ConditionalValues'),
    Middleware.CreateMapping('conditionalParameter', 'ConditionalParameter'),
    Middleware.CreateMapping('condition', 'Condition'),
    Middleware.CreateMapping('isRequired', 'IsRequired'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('createdDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('createdBy', 'CreatedBy'),
    Middleware.CreateMapping('modifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('modifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('updatedBy', 'UpdatedBy'),
    Middleware.CreateMapping('isLocked', 'IsLocked'),
    Middleware.CreateMapping('order', 'Order'),
    // Error Fields
    Middleware.CreateMapping('status', 'Status'),
    Middleware.CreateMapping('errorMessage', 'ErrorMessage'),
    Middleware.CreateMapping('errorType', 'ErrorType')
];

const getEntityMapping = [
    Middleware.CreateMapping('entityName', 'EntityName'),
    Middleware.CreateMapping('entityDisplayName', 'EntityDisplayName'),
    Middleware.CreateMapping('entityDescription', 'EntityDescription'),
    Middleware.CreateMapping('entityAccessControl', 'EntityAccessControl'),
    Middleware.CreateMapping('languageName', 'LanguageName'),
    Middleware.CreateArrayMapping('fields', 'Fields'),
    Middleware.CreateMapping('entityId', 'EntityId'),
    Middleware.CreateMapping('fieldName', 'FieldName'),
    Middleware.CreateMapping('displayName', 'DisplayName'),
    Middleware.CreateMapping('entityDetailId', 'EntityDetailId'),
    Middleware.CreateMapping('dataType', 'DataType'),
    Middleware.CreateMapping('description', 'Description'),
    Middleware.CreateMapping('isUnique', 'IsUnique'),
    Middleware.CreateMapping('isSearchable', 'IsSearchable'),
    Middleware.CreateMapping('isConditional', 'IsConditional'),
    Middleware.CreateMapping('isEditable', 'IsEditable'),
    Middleware.CreateMapping('fieldEntityName', 'FieldEntityName'),
    Middleware.CreateMapping('entityName', 'EntityName'),
    Middleware.CreateMapping('entityFields', 'EntityFields'),
    Middleware.CreateMapping('attributeName', 'AttributeName'),
    Middleware.CreateMapping('attributeId', 'AttributeId'),
    Middleware.CreateMapping('accessControl', 'AccessControl'),
    Middleware.CreateMapping('conditionalValues', 'ConditionalValues'),
    Middleware.CreateMapping('condition', 'Condition'),
    Middleware.CreateMapping('conditionalParameter', 'ConditionalParameter'),
    Middleware.CreateMapping('isRequired', 'IsRequired'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('createdDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('createdBy', 'CreatedBy'),
    Middleware.CreateMapping('modifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('updatedBy', 'UpdatedBy'),
    Middleware.CreateMapping('order', 'Order')
];

const deleteMapping = [Middleware.CreateMapping('entityName', 'EntityName'), Middleware.CreateMapping('isActive', 'IsActive')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Entity', 'GET')) {
        Middleware.RegisterMapping('Entity', 'GET', getEntityMapping);
        Middleware.RegisterMapping('Entity', 'POST', createEntityMapping);
        Middleware.RegisterMapping('Entity', 'DELETE', deleteMapping);
    }
}

const Entity = {
    ConfigureMiddleware
};

export default Entity;
