import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';

// handle missing translations
// We don't want to send errors on UI, just replacing the text with translation id , which in our case is english text
const onMissingTranslation = ({ translationId }) => {
    return `${translationId}`;
  };

class TranslateWrapper extends Component {
    render() {
        let translatedText = this.props.children.toString();
        return <Translate id={translatedText} options={{ onMissingTranslation }} />
    }
};

export default TranslateWrapper;
