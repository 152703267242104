import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoutAction } from '../actions/actions';
import { withOktaAuth } from '@okta/okta-react';

class LogOut extends Component {
    constructor(props) {
        super(props);
        this.props.logout();
        this.props.authService.logout('/');
    }

    render() {
        return (
            <main>
                <h3>Please Wait... Logging you out shortly...</h3>
            </main>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logoutAction())
});

export default withOktaAuth(connect(null, mapDispatchToProps)(LogOut));
