var setInformation = () => {
    // body
};
var clearInfo = () => {
    // body
};

var addInfo = (info) => {
    setInformation(info);
};

var createInfo = (name, title, message, severity, redirectUrl) => {
    return { name, title, message, severity, redirectUrl, date: Date.now(), isRead: false };
};

var registerReducerVariables = (setInformationFunction, clearInfoFunction) => {
    setInformation = setInformationFunction;
    clearInfo = clearInfoFunction;
};

var clearInfoOnName = (name) => {
    if (name.length <= 0) {
        console.error('Attempted to Clear Info without name when one is required');
        return;
    }

    clearInfo(name);
};

var clearAll = () => {
    clearInfo('');
};

const LoggingUtilities = {
    Register: registerReducerVariables,
    CreateInfo: createInfo,
    Add: addInfo,
    Clear: clearInfoOnName,
    ClearAll: clearAll
};

export default LoggingUtilities;
