export const SET_ERRORS = 'SET_ERRORS';
export const HIDE_ERROR_DIALOG = 'HIDE_ERROR_DIALOG';

export const LOGOUT = 'LOGOUT';

export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN';
export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN';

export const SERVICE_WORKER_UPDATE = 'SERVICE_WORKER_UPDATE';

// Master Actions
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const setPageTitleAction = (pageTitle) => ({
    type: SET_PAGE_TITLE,
    payload: pageTitle
});

export const SET_MASTER_DATA_INITIALIZED = 'SET_MASTER_DATA_INITIALIZED';
export const setMasterDataInitialized = (isInitialized) => ({
    type: SET_MASTER_DATA_INITIALIZED,
    payload: isInitialized
});


// Modal Reducers
export const INVERT_MODAL_BOOLEANS = 'INVERT_MODAL_BOOLEANS';
export const invertModalBoolean = (name) => ({
    type: INVERT_MODAL_BOOLEANS,
    payload: { name }
});

export const setErrorsAction = (title, errors, errorstack) => ({
    type: SET_ERRORS,
    payload: { title, errors, errorstack }
});

export const hideErrorDialogAction = () => ({
    type: HIDE_ERROR_DIALOG,
    payload: {}
});

export const CLEAR_ONE_ATTR_FILTER = 'CLEAR_ONE_ATTR_FILTER';
export const clearOneAttrFilter = (filter) => ({
    type: CLEAR_ONE_ATTR_FILTER,
    payload: { filter }
});

export const SET_ITEM_TYPE_FILTER = 'SET_ITEM_TYPE_FILTER';
export const setItemTypeFilter = (name, option) => ({
    type: SET_ITEM_TYPE_FILTER,
    payload: { name, option }
});

export const logoutAction = () => ({
    type: LOGOUT,
    payload: {}
});

export const showLoadingScreenAction = (title) => ({
    type: SHOW_LOADING_SCREEN,
    payload: { title }
});

export const hideLoadingScreenAction = () => ({
    type: HIDE_LOADING_SCREEN,
    payload: {}
});

// Actions for footprintReducer
export const SET_UOM_LVL_VALUES = 'SET_UOM_LVL_VALUES';
export const setDetailUOMLvl = (values) => ({
    type: SET_UOM_LVL_VALUES,
    payload: { values }
});

export const SET_FP_VALUE = 'SET_FP_VALUE';
export const setFootprintValues = (value) => ({
    type: SET_FP_VALUE,
    payload: { value }
});

export const CLEAR_FP_VALUE = 'CLEAR_FP_VALUE';
export const clearFootprintValue = () => ({
    type: CLEAR_FP_VALUE,
    payload: {}
});

export const CLEAR_FP_LINE_VALUE = 'CLEAR_FP_LINE_VALUE';
export const clearFootprintLineValue = (index) => ({
    type: CLEAR_FP_LINE_VALUE,
    payload: { index }
});

export const DELETE_FP_LINE_DETAIL = 'DELETE_FP_LINE_DETAIL';
export const deleteFootprintLineDetail = (index) => ({
    type: DELETE_FP_LINE_DETAIL,
    payload: { index }
});

export const SET_FP_LINE_VALUES = 'SET_FP_LINE_VALUES';
export const setFootprintLineValues = (valueArray, index) => ({
    type: SET_FP_LINE_VALUES,
    payload: { valueArray, index }
});

export const SET_FP_LINE_BOOLEANS = 'SET_FP_LINE_BOOLEANS';
export const setFootprintLineBooleans = (newBool, index) => ({
    type: SET_FP_LINE_BOOLEANS,
    payload: { newBool, index }
});

export const ADD_NEW_FP_LINE = 'ADD_NEW_FP_LINE';
export const incrementFPIndex = () => ({
    type: ADD_NEW_FP_LINE,
    payload: {}
});

export const CLEAR_POSTED_FP = 'CLEAR_POSTED_FP';
export const clearPostedFP = () => ({
    type: CLEAR_POSTED_FP,
    payload: {}
});

export const SHOW_TOAST = 'SHOW_TOAST';
export const showToast = (toastMessage, toastType) => ({
    type: SHOW_TOAST,
    payload: { toastMessage, toastType }
});

export const SERVICE_WORKER_UPDATED = 'SERVICE_WORKER_UPDATED';
export const serviceWorkerUpdate = () => ({
    type: SERVICE_WORKER_UPDATED
});
