import { withStyles } from '@material-ui/core/styles';
import { DrcMain, DrcTooltip, DrcButton } from 'driscolls-react-components';
import React from 'react';
import CatJpg from '../images/sorry_cat.jpg';

const styles = () => ({
    main: {
        marginBottom: '40px'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '40'
    },
    content: {
        flex: 1
    }
});

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }
    componentDidUpdate = (revProps) => {
        if (revProps.path !== this.props.path)
            this.setState({
                error: null,
                errorInfo: null
            });
    };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div className={this.props.classes.content}>
                    <DrcMain className={this.props.classes.main}>
                        <div style={{ textAlign: 'center' }}>
                            <DrcTooltip tipText="I couldn't save your data!!!">
                                <img src={CatJpg} alt="I can't say how sorry I am!" />
                            </DrcTooltip>
                            <h1>Unfortunately Something failed.</h1>
                            <p style={{ maxWidth: 500, margin: 'auto' }}>
                                I was so careful, but still I couldn't stop this from breaking.It will be impossible for me to fix anything at this
                                point.So kindly start over!
                            </p>
                            <DrcButton
                                isSecondary
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                Try, Try Again
                            </DrcButton>
                        </div>
                    </DrcMain>
                </div>
            );
        }

        return this.props.children;
    }
}
export default withStyles(styles)(ErrorBoundary);
