export const REASON_CODES = 'REASON_CODES';
export const setReasonCodes = (reasonCodes) => ({
    type: REASON_CODES,
    payload: reasonCodes
});

export const STATUSES = 'STATUSES';
export const setStatuses = (statuses) => ({
    type: STATUSES,
    payload: statuses
});

export const SET_GRID_DATA = 'SET_GRID_DATA';
export const setGridData = (name, data) => ({
    type: SET_GRID_DATA,
    payload: { name, data }
});

export const SET_SELECTED_FOOTPRINT = 'SET_SELECTED_FOOTPRINT';
export const setSelectedFootprint = (rowKey) => ({
    type: SET_SELECTED_FOOTPRINT,
    payload: { rowKey }
});

export const SET_ITEM_CODE_OPTIONS = 'SET_ITEM_CODE_OPTIONS';
export const setItemCodeOptions = (options) => ({
    type: SET_ITEM_CODE_OPTIONS,
    payload: { options }
});

export const SET_FP_CODE_OPTIONS = 'SET_FP_CODE_OPTIONS';
export const setFPCodeOptions = (options, itemCode) => ({
    type: SET_FP_CODE_OPTIONS,
    payload: { options, itemCode }
});

export const ADD_FP_CODE_OPTION = 'ADD_FP_CODE_OPTION';
export const addFPCodeOption = (option) => ({
    type: ADD_FP_CODE_OPTION,
    payload: { option }
});

export const SET_MASTER_DATA_REDIRECT_URL = 'SET_MASTER_DATA_REDIRECT_URL';
export const setInitializeRedirectUrl = (redirectUrl) => ({
    type: SET_MASTER_DATA_REDIRECT_URL,
    payload: redirectUrl
});

export const SET_MASTER_DATA_INITIALIZED = 'SET_MASTER_DATA_INITIALIZED';
export const setMasterDataInitialized = (isInitialized) => ({
    type: SET_MASTER_DATA_INITIALIZED,
    payload: isInitialized
});

export const SET_LANGUAGE_OPTIONS = 'SET_LANGUAGE_OPTIONS';
export const setLanguageOptions = (languages) => ({
    type: SET_LANGUAGE_OPTIONS,
    payload: languages
});

export const SET_DATATYPE_LIST = 'SET_DATATYPE_LIST';
export const setDataTypeList = (datatypes) => ({
    type: SET_DATATYPE_LIST,
    payload: datatypes
});

export const SET_ATTRIBUTE_OPTIONS_LIST = 'SET_ATTRIBUTE_OPTIONS_LIST';
export const setAttributeOptionsList = (attributes) => ({
    type: SET_ATTRIBUTE_OPTIONS_LIST,
    payload: attributes
});

export const SET_ENTITY_OPTIONS_LIST = 'SET_ENTITY_OPTIONS_LIST';
export const setEntityOptionsList = (entities) => ({
    type: SET_ENTITY_OPTIONS_LIST,
    payload: entities
});

export const SET_GROUP_OPTIONS_LIST = 'SET_GROUP_OPTIONS_LIST';
export const setGroups = (groups) => ({
    type: SET_GROUP_OPTIONS_LIST,
    payload: groups
});

export const SET_BERRY = 'SET_BERRY';
export const setBerry = (berry) => ({
    type: SET_BERRY,
    payload: berry
});
