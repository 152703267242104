import FPHeaderData from './fpHeaderData';
import ItemCodeData from './itemCodeData';
import FPLineData from './fpLineData';
import Selections from './selections';
import Attributes from './attributes';
import UserEntityList from './userEntityList';
import DatatypeList from './datatypeList';
import EntityMetaData from './entityMetaData';
import languageOptions from './languageOptions';
import DataEntityList from './dataEntityList';
import Entity from './entity';
import AttributeList from './attributesList';
import Attribute from './attribute';
import ValidateFileData from './validateFileData';
import EntityNameExists from './entityNameExists';
import AttributeNameExists from './attributeNameExists';
import s3Credentials from './s3Credentials';
import Locations from './locations';
import Others from './others';
import Plots from './plots';
import PlotPlantingSeason from './plotPlantingSeason';
import PlotFactors from './plotFactors';
import Roles from './roles';
import Translations from './translations';
import BulkUpdate from './bulkUpdate';
import Ranches from './ranches';
import TreatmentTypes from './treatmentTypes';
import Variety from './variety';
import ApplicationIds from './applicationIds';

function ConfigureAllMiddleware() {
    FPHeaderData.ConfigureMiddleware();
    FPLineData.ConfigureMiddleware();
    ItemCodeData.ConfigureMiddleware();
    Selections.ConfigureMiddleware();
    UserEntityList.ConfigureMiddleware();
    DatatypeList.ConfigureMiddleware();
    EntityMetaData.ConfigureMiddleware();
    languageOptions.ConfigureMiddleware();
    DataEntityList.ConfigureMiddleware();
    Attributes.ConfigureMiddleware();
    Entity.ConfigureMiddleware();
    Attribute.ConfigureMiddleware();
    AttributeList.ConfigureMiddleware();
    ValidateFileData.ConfigureMiddleware();
    EntityNameExists.ConfigureMiddleware();
    AttributeNameExists.ConfigureMiddleware();
    s3Credentials.ConfigureMiddleware();
    Locations.ConfigureMiddleware();
    Others.ConfigureMiddleware();
    Plots.ConfigureMiddleware();
    PlotFactors.ConfigureMiddleware();
    PlotPlantingSeason.ConfigureMiddleware();
    Roles.ConfigureMiddleware();
    Translations.ConfigureMiddleware();
    BulkUpdate.ConfigureMiddleware();
    Ranches.ConfigureMiddleware();
    TreatmentTypes.ConfigureMiddleware();
    Variety.ConfigureMiddleware();
    ApplicationIds.ConfigureMiddleware();
}

const Middleware = {
    ConfigureAllMiddleware
};

export default Middleware;
