import { Middleware } from 'one-ring';

const dataEntityListMapping = [
    Middleware.CreateMapping('IsLocked', 'IsLocked'),
    Middleware.CreateMapping('EntityId', 'EntityId'),
    Middleware.CreateMapping('TableName', 'TableName'),
    Middleware.CreateMapping('EntityName', 'EntityName'),
    Middleware.CreateMapping('EntityDescription', 'EntityDescription'),
    Middleware.CreateMapping('IsActive', 'IsActive'),
    Middleware.CreateMapping('DefaultLanguage', 'DefaultLanguage'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('UpdatedBy', 'UpdatedBy'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('DataEntityList', 'GET')) {
        Middleware.RegisterMapping('DataEntityList', 'GET', dataEntityListMapping);
    }
}

const dataEntityList = {
    ConfigureMiddleware
};

export default dataEntityList;
