export const SET_ATTRIBUTE_LIST = 'SET_ATTRIBUTE_LIST';
export const setAttributeList = (data) => ({
    type: SET_ATTRIBUTE_LIST,
    payload: data
});

export const SET_ATTRIBUTE_TO_UPDATE = 'SET_ATTRIBUTE_TO_UPDATE';
export const setUpdateAttribute = (attribute) => ({
    type: SET_ATTRIBUTE_TO_UPDATE,
    payload: attribute
});
