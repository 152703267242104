import { Middleware } from 'one-ring';

const entityMetaDataMapping = [
    Middleware.CreateArrayMapping('Result', 'Result'),
    Middleware.CreateMapping('EntityName', 'EntityName'),
    Middleware.CreateMapping('EntityDisplayName', 'EntityDisplayName'),
    Middleware.CreateMapping('EntityDescription', 'EntityDescription'),
    Middleware.CreateArrayMapping('Fields', 'Fields'),
    Middleware.CreateMapping('FieldName', 'FieldName'),
    Middleware.CreateMapping('DisplayName', 'DisplayName'),
    Middleware.CreateMapping('Datatype', 'Datatype'),
    Middleware.CreateMapping('Options', 'Options'),
    Middleware.CreateMapping('IsSearchable', 'IsSearchable'),
    Middleware.CreateMapping('IsUpdatable', 'IsUpdatable'),
    Middleware.CreateMapping('IsBerry', 'IsBerry'),
    Middleware.CreateMapping('IsAsync', 'IsAsync'),
    Middleware.CreateMapping('EntityName', 'EntityName'),
    Middleware.CreateMapping('OptionLabel', 'OptionLabel'),
    Middleware.CreateMapping('ConditionalField', 'ConditionalField'),
    Middleware.CreateMapping('ConditionalRule', 'ConditionalRule'),
    Middleware.CreateMapping('OptionValue', 'OptionValue'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('EntityMetaData', 'GET')) {
        Middleware.RegisterMapping('EntityMetaData', 'GET', entityMetaDataMapping);
    }
}

const entityMetaData = {
    ConfigureMiddleware
};

export default entityMetaData;
