import { SET_IS_ADMIN, SET_READONLY_ACCESS } from '../actions/userActions';

const initialState = {
    isAdmin: false,
    preferredLanguage: 'en',
    isReadOnly: false
};

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.payload
            };
        case SET_READONLY_ACCESS:
            return {
                ...state,
                isReadOnly: action.payload
            };
        default:
            return state;
    }
};

export default UserReducer;
