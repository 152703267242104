import {
    SET_PLOTS_DATA,
} from '../actions/plotsActions';

const initialState = {
    metaData: null,
    records: [],
    filters: [],
    totalRecords: 0
};

const PlotsReducer  = (state = initialState, action) => {
    if (action.type === SET_PLOTS_DATA) {
        let records = [];
            records = action.payload.records;

            return Object.assign({}, state, {
                records,
                totalRecords: action.payload.count
            });
    } else {
        return state;
    }
};

export default PlotsReducer ;
